import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiService } from "../../services/api.service";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function CreateMessage(): ReactElement {
  const [startDate, setStartDate] = useState(new Date() as any | null);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const [users, setUsers] = useState<any[]>([]);
  const [usersID, setUserID] = useState("");

  const apiService = new ApiService();

  const onSubmit = (data: any) => {
    PostData(data);
  };

  const PostData = async (data: any) => {
    data.trainerISID = 10;
    data.userID = parseInt(usersID);
    data.messageStatus = 1;
    data.messagedate = startDate;
    const response = await apiService.PostData("api/Message/AddMessage", data);
  };

  const fetchUsers = async () => {
    const response = await apiService.getAllUsers();
    setUsers(response);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Create Message</h3>
      <hr></hr>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {/* FIRST COL */}
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message Title"
                id="form3Example4"
                {...register("messageTitle", {
                  required: "Message Title name is required",
                })}
              />
              {errors.messageTitle ? (
                <>
                  {errors.messageTitle.type === "required" && (
                    <span className="small error">
                      {errors.messageTitle.message}
                    </span>
                  )}
                </>
              ) : null}
            </div>
            <div className="form-group">
              <textarea
                rows={10}
                id="form3Example4"
                className="form-control"
                placeholder="Message Details"
                {...register("messageDetails", {
                  required: "Message Details is required",
                })}
              />
              {errors.messageDetails ? (
                <>
                  {errors.messageDetails.type === "required" && (
                    <span className="small error">
                      {errors.messageDetails.message}
                    </span>
                  )}
                </>
              ) : null}
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message URL"
                id="form3Example4"
                {...register("messageURL", {})}
              />
            </div>

            {/* @ts-ignore*/}
            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={(dateSelected) => setStartDate(dateSelected)}
            />

            <div className="form-group">
              <div className="custom_select">
                <select
                  className="form-control select-active"
                  {...register("messageStatus", { required: true })}
                  onChange={(e) => setUserID(e.target.value)}
                >
                  <option value="0">Message Status...</option>
                  <option value="1">Published</option>

                  <option value="2">Draft</option>
                  <option value="3">Template</option>
                </select>{" "}
                {errors.messageStatus ? (
                  <>
                    <span className="small error">
                      {errors.messageStatus.message}
                    </span>
                  </>
                ) : null}
              </div>
            </div>

            <div className="form-group">
              <div className="custom_select">
                <select
                  className="form-control select-active"
                  {...register("Country", { required: true })}
                  onChange={(e) => setUserID(e.target.value)}
                >
                  <option disabled value="0">
                    Select user...
                  </option>
                  {users &&
                    users.map((item: any) => (
                      <option value={item.userID}>
                        {item.userFirstname} {item.userLastname}
                      </option>
                    ))}
                </select>{" "}
                {errors.Country ? (
                  <>
                    <span className="small error">
                      {errors.Country.message}
                    </span>
                  </>
                ) : null}
              </div>
            </div>
            <hr></hr>
            <button
              type="submit"
              className="btn btn-success hover-up"
              name="login"
            >
              Save Message
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
export default CreateMessage;
