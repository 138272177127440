import React, { ReactElement, useState, useContext } from "react";
import Cookie from "js-cookie";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router";
import { setUser, getUser } from "../../services/auth.service";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

function PasswordSent(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const [userEmail, setUserEmail] = useState("");
  let [hidden, setHidden] = useState("");

  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    fetch("api/User/PasswordReset", {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        userEmail: userEmail,
      }),
    })
      .then((Response) => Response.json())
      .then((result) => {
        navigate("/Password-Sent");
      })
      .catch((error) => {});
  };

  return (
    <main className="main">
      <section className="pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-40 d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="col-lg-12 pt-40 m-auto d-flex align-items-center justify-content-center">
                  <div className="login_wrap p-40 widget-taber-content p-80 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                    <div
                      className="padding_eight_all bg-white"
                      style={{
                        paddingLeft: "180px",
                        paddingRight: "180px",
                        paddingTop: "40px",
                        paddingBottom: "40px",
                      }}
                    >
                      <div className="heading_s1">
                        <h3 className="pb-30">Password Sent</h3>
                        {hidden !== "" ? (
                          <h4 className="pb-30 error">{hidden}</h4>
                        ) : null}
                      </div>
                      <p>
                        If you have an account with us and is active, you should
                        receive details about your password shortly
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default PasswordSent;
