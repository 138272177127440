import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import Moment from "moment";
import { FormatDate } from "../../helpers";

function Workouts(): ReactElement {
  const { userData } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [workouts, setWorkouts] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const apiService = new ApiService();

  const fetchWorkouts = async () => {
    setLoading(true);
    var response;
    response = await apiService.getAllWorkoutsByUserID(userData.ISID);
    setWorkouts(response);

    setLoading(false);
    window.scrollTo(0, 0);
  };
  let navigate = useNavigate();

  const handleClick = (e: any, workoutID: any) => {
    let path;

    path = `/WorkoutDetails?id=${e}&workoutID=${workoutID}`;

    navigate(path);
  };

  const handleKeyword = (e: any) => {
    setSearchKeyword(e);
    const apiService = new ApiService();
    const fetchWorkoutsByKeyword = async () => {
      const response = await apiService.getAllWorkoutByKeywords(e);
      setWorkouts(response);
      setLoading(false);
    };
    fetchWorkoutsByKeyword();
  };

  useEffect(() => {
    fetchWorkouts();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3 className="float-start">Workouts</h3>

      <div className="clearfix"></div>
      <hr></hr>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Programme Name</th>
            <th scope="col">Date</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {workouts &&
            workouts.map((item, index) => (
              <tr onClick={() => handleClick(item.workID, item.workoutID)}>
                <th scope="row">{index + 1}</th>
                <td>{item.work.workTitle} </td>
                <td> {FormatDate(item.dateCreated)} </td>
                <td>View</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default Workouts;
