import React, { ReactElement, useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

function Progress({ progress }: any): ReactElement {
  const [progressCount, setProgressCount] = useState(0);
  const [progressData, setProgressData] = useState<any[]>([]);
  const progData = progress;
  const calcProgress = () => {
    setProgressData(progress);
    setProgressCount(progress?.length * 12.5);
  };

  useEffect(() => {
    calcProgress();
  }, [progress]);

  return (
    <div className="container p-0 mb-4 bg-light rounded-3">
      <div className="portlet">
        <div className="portlet-header">
          <h3>
            <i className="fa fa-compass"></i>
            Training Progress
          </h3>
          <hr></hr>
        </div>

        <div className="portlet-content">
          <div className="progress-stat">
            <div className="progress-stat-label">Programme Completion</div>

            <ProgressBar
              striped
              variant="success"
              now={progressCount}
              label={`${progressCount}%`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Progress;
