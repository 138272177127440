import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { ApiService } from "../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { useForm } from "react-hook-form";
import { FormatDate } from "../../helpers";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Badge,
} from "react-bootstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { isTemplateExpression } from "typescript";

function GymCalendar(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  let data;
  const product = [] as any;

  const { userData, setUserData } = useContext(AuthContext);
  const [chartData, setChartData] = useState<any[]>([]);
  const [products, setProduct] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedDate] = useState("");

  const events = [{ title: "Meeting ", start: new Date() }];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const apiService = new ApiService();

  const fetchChartData = async () => {
    const response = await apiService.getAllChartDataGymHours(userData.ISID);
    setChartData(response);

    const data =
      response &&
      response.map((item: any, index: any) =>
        product.push({
          start: new Date(item.dateCreated),
          title: item.gymValue.toString() + " hours",
        })
      );

    setProduct(product);
  };
  useEffect(() => {
    fetchChartData();
  }, []);

  function renderEventContent(eventInfo: any) {
    return (
      <>
        <span
          style={{
            textAlign: "center",
            width: "100%",
            cursor: "default",
          }}
        >
          {/* <b>{eventInfo.timeText}</b> */}

          {eventInfo.event.title}
        </span>
      </>
    );
  }

  const handleDateClick = (arg: any) => {
    // bind with an arrow function

    handleShow();
    setSelectedDate(arg.dateStr);
    // get a list of all dates by user ID
  };

  const deleteEntry = async (e: any) => {
    const response = await apiService.deleteGymByID(e);

    if (response === 1) {
      handleClose();
      fetchChartData();
    } else {
    }
  };

  const onSubmit = async (data: any) => {
    data.ISID = userData.ISID;
    data.dateCreated = new Date(selectedData);
    const response = await apiService.PostData("api/Gym/AddGym", data);

    if (response === 1) {
      handleClose();
      fetchChartData();
    }
    reset();
    fetchChartData();
  };

  return (
    <div>
      <div className="container p-5 mb-4 bg-light rounded-3">
        <h3>Gym Hours Calendar</h3>
        <p>Click on a date to record your gym hours</p>
        <hr></hr>
        <p>{product}</p>
        <div className="row">
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            weekends={true}
            events={products}
            eventContent={renderEventContent}
            dateClick={handleDateClick}
          />
        </div>
      </div>
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {FormatDate(new Date(selectedData)).toString()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              {chartData &&
                chartData.map((item: any) =>
                  FormatDate(new Date(item.dateCreated)) ==
                  FormatDate(new Date(selectedData)) ? (
                    <tr
                      style={{
                        borderBottom: "#eee 1px solid",
                        padding: "20px",
                      }}
                    >
                      <td>{item.gymValue + " hours | "}</td>
                      <td>
                        {" "}
                        <button
                          onClick={(e) => deleteEntry(item.gymID)}
                          className="btn btn-success"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ) : null
                )}
            </table>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="ct-chart" id="chartHours">
                {/*@ts-ignore*/}

                <input
                  type="text"
                  className="form-control"
                  style={{ marginTop: "20px" }}
                  placeholder="Gym Hours"
                  id="gymValue"
                  {...register("gymValue", {
                    required: "Gym Hours is required",
                  })}
                >
                  {errors.gymValue ? (
                    <>
                      {errors.gymValue.type === "required" && (
                        <span className="small error">
                          {errors.gymValue.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </input>
                <input
                  type="text"
                  className="form-control"
                  id="dateCreated"
                  style={{ display: "none" }}
                  {...register("dateCreated", {
                    required: "Date is required",
                  })}
                  value={FormatDate(new Date(selectedData)).toString()}
                >
                  {errors.dateCreated ? (
                    <>
                      {errors.dateCreated.type === "required" && (
                        <span className="small error">
                          {errors.dateCreated.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </input>
                <button className="btn btn-success btn-sm">Update</button>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}
export default GymCalendar;
