import React, { ReactElement, useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { ApiService } from "../../services/api.service";
import { useLocation, useNavigate } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import { FormatVimeoLink } from "../../helpers";
import { useForm } from "react-hook-form";

interface Workout {
  workTitle: string;
  workEquip: number;
  workTime: number;
  workIntro: number;
  workCatID: number;
  infID: number;
  templateID: number;
}

interface Exer {
  exerID: number;
  exerName: string;
  exerSet: string;
  exerRep: string;
  exerRest: string;
  exerImgLink: string;
  exerCode: string;
  workID: number;
  videoID: number;
}

function EditWorkout(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const workoutID = query.get("workoutID");

  const [savedWorkoutID, setSavedWorkoutID] = useState(Number(id));
  const [savedMessage, setSavedMessage] = useState("");

  let navigate = useNavigate();
  const [isTemplate, setIsTemplate] = useState(false);
  const [vimeoName, setVimeoName] = useState("");
  const [vimeoID, setVimeoID] = useState(0);

  const [workoutTitle, setWorkoutTitle] = useState("");
  const [exercises, setExercises] = useState<any[]>([]);
  const [vimeoVideos, setVimeoVideos] = useState<any[]>([]);
  const [exer, setExer] = useState<any[]>([]);
  const [workouts, setWorkouts] = useState<any[]>([]);

  const [savedID, setSavedID] = useState("");

  const apiService = new ApiService();

  const [exerSaved, setExerSaved] = useState<any>({
    exerID: 0,
    exerName: "",
    exerSet: "",
    exerRep: "",
    exerRest: "",
    exerImgLink: "",
    exerCode: "",
    workID: 0,
    videoID: 0,
  });

  const [workout, setWorkout] = useState({
    workTitle: "",
    workEquip: 0,
    workTime: "",
    workIntro: "",
    workCatID: 0,
    infID: 0,
    templateID: 0,
  });

  const handleInputChange = (event: any) => {
    const values: any = { ...workout };

    if (workout === undefined) {
      values.push({
        workTitle: "",
        workEquip: 0,
        workTime: "",
        workIntro: "",
        workCatID: 0,
        infID: 0,
        templateID: isTemplate === true ? 1 : 0,
      });
    }
    const updatedValue = event.target.name;
    values[updatedValue] = event.target.value;
    values["templateID"] = isTemplate ? 1 : 0;

    setWorkout(values);
  };

  const handleVimeoVideoChange = (event: any, name: any) => {
    const values: any = { ...exerSaved };

    const updatedValue = "videoID";
    values[updatedValue] = event;

    setExerSaved(values);

    setVimeoName(name);
    setVimeoID(event);
    findVimeoVideo(event);
  };

  const handleExerChange = (event: any) => {
    const values: any = { ...exerSaved };

    if (exerSaved === undefined) {
      values.push({
        exerName: "",
        exerSet: 0,
        exerRep: 0,
        exerRest: "",
        exerImgLink: "",
        exerCode: "",
        videoID: 0,
        workID: savedID,
      });
    }
    const updatedValue = event.target.name;
    values[updatedValue] = event.target.value;
    values["workID"] = id;
    setExerSaved(values);
  };

  const PostWorkoutData = async () => {
    const response = await apiService.PostData("../api/Work/AddWork", workout);
    setSavedID(response);
    setSavedWorkoutID(response);

    fetchWorkoutByID(response);

    setSavedMessage("Saved");
    setTimeout(() => {
      setSavedMessage("");
    }, 3000);
  };

  const loadExerUp = async (e: any) => {
    const response = await apiService.getExerByID(e);
    setExerSaved(response);
  };

  const onSubmit = async (data: any) => {
    data.videoID = vimeoID;
    data.workID = savedWorkoutID;

    const response = await apiService.PostData("../api/Exer/AddExer", data);
    fetchExerByWorkID(savedWorkoutID);

    reset();

    if (response === 1) {
      reset();
      fetchExerByWorkID(savedWorkoutID);
    }
  };

  const ClearForm = () => {
    var exerCode: HTMLInputElement;
    exerCode = document.getElementById("exerCode") as HTMLInputElement;
    exerCode.defaultValue = "";

    var exerRep: HTMLInputElement;
    exerRep = document.getElementById("exerRep") as HTMLInputElement;
    exerRep.defaultValue = "";

    var exerSet: HTMLInputElement;
    exerSet = document.getElementById("exerSet") as HTMLInputElement;
    exerSet.defaultValue = "";

    var exerName: HTMLInputElement;
    exerName = document.getElementById("exerName") as HTMLInputElement;
    exerName.defaultValue = "";

    var exerRest: HTMLInputElement;
    exerRest = document.getElementById("exerRest") as HTMLInputElement;
    exerRest.defaultValue = "";

    var videoID: HTMLInputElement;
    videoID = document.getElementById("videoID") as HTMLInputElement;
    videoID.defaultValue = "";
  };

  const fetchWorkoutByID = async (e: number) => {
    const response = await apiService.getWorkoutByID(e);
    setWorkout(response);
  };
  const populateField = async (e: number) => {
    const response = await apiService.getWorkoutByID(e);
    setWorkout(response);
  };

  const DeleteExer = async (e: number) => {
    const response = await apiService.deleteExerByID(e);
    fetchExerByWorkID(savedWorkoutID);
  };

  const copyToTemplate = async () => {
    const response = await apiService.PostData("../api/Work/SaveCopy", workout);
    if (response === 1) {
      navigate("/Templates-Admin");
    } else {
    }
  };

  const deleteWorkout = async () => {
    const response = await apiService.DeleteWorkout(savedWorkoutID);
    console.log(savedWorkoutID);
    if (response === 1) {
      navigate("/Workouts-Admin");
    } else {
    }
  };

  const copyToLive = async () => {
    const response = await apiService.PostData("../api/Work/SaveLive", workout);
    if (response === 1) {
      navigate("/Workouts");
    } else {
    }
  };
  const deleteTemplate = async () => {
    const response = await apiService.PostData(
      "../api/Work/ArchiveWork",
      workout
    );
    if (response === 1) {
      navigate("/Templates-Admin");
    } else {
    }
  };

  const fetchExerByWorkID = async (e: number) => {
    const response = await apiService.getAllExerByWorkID(e);

    setExer(response);
  };

  const findVimeoVideo = async (e: any) => {
    if (e.length > 3) {
      const response = await apiService.getAllVimeoVideosByKeyword(e);
      setVimeoVideos(response.data);
    }
  };

  useEffect(() => {
    fetchWorkoutByID(Number(id));
    fetchExerByWorkID(Number(id));
    const template = query.get("template");
    const templateStatus = Boolean(template);
    if (templateStatus) {
      setIsTemplate(templateStatus);
    }
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>
        Edit Workout {isTemplate ? " (Template)" : null}
        <React.Fragment>
          <button
            onClick={(e) => deleteWorkout()}
            className="btn btn-sm btn-danger form-inline float-end"
          >
            Delete Workout
          </button>
          {isTemplate ? (
            <span>
              <button
                onClick={(e) => copyToLive()}
                className="btn btn-success form-inline float-end"
              >
                Make Live Copy
              </button>
              <button
                onClick={(e) => deleteTemplate()}
                className="btn btn-danger form-inline float-end"
              >
                Delete Template{" "}
              </button>
            </span>
          ) : (
            <button
              onClick={(e) => copyToTemplate()}
              className="btn btn-success form-inline float-end"
            >
              Copy to template
            </button>
          )}
        </React.Fragment>
        <h1></h1>
      </h3>
      <hr></hr>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Workout Title</Form.Label>
          <Form.Control
            className={
              workout.workTitle === "" ? "text-muted error" : "text-muted"
            }
            type="email"
            placeholder="Enter workout title"
            name="workTitle"
            onChange={(e) => handleInputChange(e)}
            defaultValue={workout.workTitle}
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Workout Category</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="workCatID"
            onChange={(e) => handleInputChange(e)}
            className={workout.workCatID <= 0 ? "error" : ""}
          >
            <option value="0" selected={0 === workout.workCatID}>
              Select workout category
            </option>
            <option value="1" selected={1 === workout.workCatID}>
              Back
            </option>
            <option value="2" selected={2 === workout.workCatID}>
              Chest
            </option>
            <option value="3" selected={3 === workout.workCatID}>
              Core
            </option>
            <option value="4" selected={4 === workout.workCatID}>
              Legs And Glutes
            </option>
            <option value="5" selected={5 === workout.workCatID}>
              Mobility
            </option>
            <option value="17" selected={17 === workout.workCatID}>
              Power And Speed
            </option>
            <option value="18" selected={18 === workout.workCatID}>
              Rotational
            </option>
            <option value="19" selected={19 === workout.workCatID}>
              Shoulders
            </option>
            <option value="20" selected={20 === workout.workCatID}>
              Strength
            </option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Equipment Required</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="workEquip"
            onChange={(e) => handleInputChange(e)}
            className={workout.workEquip <= 0 ? "error" : ""}
            defaultValue={workout.workEquip}
          >
            <option value="0">Select if equipment is required </option>
            <option value="1" selected={1 === workout.workEquip}>
              Yes
            </option>
            <option value="2" selected={2 === workout.workEquip}>
              No
            </option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Workout time</Form.Label>
          <Form.Control
            className={
              workout.workTime == "" ? "text-muted error" : "text-muted"
            }
            type="email"
            placeholder="Enter workout time"
            name="workTime"
            onChange={(e) => handleInputChange(e)}
            defaultValue={workout.workTime}
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Workout description</Form.Label>
          <Form.Control
            className={
              workout.workIntro === "" ? "text-muted error" : "text-muted"
            }
            as="textarea"
            rows={3}
            placeholder="Enter workout description"
            name="workIntro"
            onChange={(e) => handleInputChange(e)}
            defaultValue={workout.workIntro}
          />

          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
      </Form>
      <button onClick={PostWorkoutData} className="btn btn-success form-inline">
        Save Workout Details
      </button>{" "}
      {savedMessage !== "" ? (
        <span
          style={{
            backgroundColor: "green",
            padding: 10,
            borderRadius: 10,
            color: "#fff",
            width: "100%",
            marginLeft: 20,
          }}
        >
          {savedMessage}
        </span>
      ) : null}
      <br></br>
      <br></br>
      SavedID: {savedWorkoutID}
      <hr></hr>
      <div className="row">
        <div className="col-md-5">
          <h3>Add Workout Videos</h3>
          <hr></hr>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="exerCode">
              <Form.Label>Exercise Code</Form.Label>
              <Form.Control
                type="text"
                {...register("exerCode", {
                  required: "Exercise code is required",
                })}
                name="exerCode"
                placeholder="Enter exercise code"
              />
              {errors.exerCode ? (
                <>
                  {errors.exerCode.type === "required" && (
                    <span className="small error">
                      {errors.exerCode.message}
                    </span>
                  )}
                </>
              ) : null}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exerName">
              <Form.Label>Exercise Title</Form.Label>
              <Form.Control
                type="text"
                {...register("exerName", {
                  required: "Exercise name is required",
                })}
                name="exerName"
                placeholder="Enter exercise title"
              />
              {errors.exerName ? (
                <>
                  {errors.exerName.type === "required" && (
                    <span className="small error">
                      {errors.exerName.message}
                    </span>
                  )}
                </>
              ) : null}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exerSet">
              <Form.Label>Exercise Sets</Form.Label>
              <Form.Control
                type="text"
                {...register("exerSet", {
                  required: "Sets is required",
                })}
                name="exerSet"
                placeholder="Enter exercise sets"
              />
              {errors.exerSet ? (
                <>
                  {errors.exerSet.type === "required" && (
                    <span className="small error">
                      {errors.exerSet.message}
                    </span>
                  )}
                </>
              ) : null}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exerRep">
              <Form.Label>Exercise Reps</Form.Label>
              <Form.Control
                type="text"
                {...register("exerRep", {
                  required: "Reps is required",
                })}
                name="exerRep"
                placeholder="Enter exercise reps"
              />
              {errors.exerRep ? (
                <>
                  {errors.exerRep.type === "required" && (
                    <span className="small error">
                      {errors.exerRep.message}
                    </span>
                  )}
                </>
              ) : null}

              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exerRest">
              <Form.Label>Exercise Rest (Seconds)</Form.Label>
              <Form.Control
                type="text"
                {...register("exerRest", {
                  required: "Rest is required",
                })}
                name="exerRest"
                placeholder="Enter exercise rest (seconds)"
              />
              {errors.exerRest ? (
                <>
                  {errors.exerRest.type === "required" && (
                    <span className="small error">
                      {errors.exerRest.message}
                    </span>
                  )}
                </>
              ) : null}

              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="videoID">
              <Form.Label>Vimeo Video</Form.Label>
              <Form.Control
                type="text"
                {...register("videoID", {})}
                placeholder="Type to search the Vimeo Library"
                onChange={(e) => findVimeoVideo(e.target.value)}
                name="videoID"
              />
              <ul>
                {vimeoVideos &&
                  vimeoVideos.map((item, index: number) => (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        handleVimeoVideoChange(
                          FormatVimeoLink(item.link),
                          item.name
                        )
                      }
                    >
                      {item.name}
                    </li>
                  ))}
              </ul>
            </Form.Group>
            <button className="btn btn-success form-inline">
              Save Exercise Details
            </button>{" "}
          </form>

          <hr></hr>
        </div>

        <div className="col-md-7">
          <h2>Exercises</h2>
          <hr></hr>

          {exer &&
            exer.map((item, index: number) =>
              item.workID !== 0 ? (
                <div className="row">
                  <div className="col-md-3">
                    <Vimeo
                      video={item.videoID}
                      style={{ width: "100" }}
                      showTitle
                      responsive
                      className="mb-3"
                    ></Vimeo>
                  </div>
                  <div className="col-md-7 exerDetails">
                    <h3
                    // onClick={(e) => loadExerUp(item.exerID)}
                    //style={{ cursor: "pointer" }}
                    >
                      <span className="c-red">{item.exerCode}</span>.{" "}
                      {item.exerName}
                    </h3>
                    <p>
                      <b>Sets:</b> {item.exerSet} <b>Reps:</b> {item.exerRep}{" "}
                      <b>Rest:</b> {item.exerRest}
                    </p>
                  </div>
                  <div className="col-md-1">
                    <button
                      onClick={(e) => DeleteExer(item.exerID)}
                      className="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>
                  </div>
                  <hr></hr>
                </div>
              ) : null
            )}
        </div>
      </div>
    </div>
  );
}
export default EditWorkout;
