import React, { ReactElement, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { ApiService } from "../../services/api.service";
import Progress from "../Progress";
import Loader from "../Loader";
function Checklist(): ReactElement {
  const [complete, setComplete] = useState<any[]>([]);
  const [progress, setProgress] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const { userData, setUserData } = useContext(AuthContext);

  const apiService = new ApiService();

  const fetchComplete = async () => {
    setLoading(true);
    const response = await apiService.getAllCompleteByUser(1);
    setComplete(response);
    setLoading(false);
  };

  const fetchProgress = async () => {
    setLoading(true);
    const response = await apiService.getAllProgressByUser(userData.ISID);
    setProgress(response);
    setLoading(false);
  };

  const checkIfProgressExists = (complete: any, completeID: string) => {
    return complete.find((element: any) => {
      return element.completeID === completeID;
    });
  };

  const getProgressID = (complete: any, completeID: string) => {
    return complete.find((element: any) => {
      if (element.completeID === completeID) {
        return element.progressID;
      }
    });
  };

  const saveProductCategory = (
    e: any,
    completeyID: number,
    progressyID: any
  ) => {
    if (e.target.checked) {
      let data = {
        progressID: 0,
        completeID: completeyID,
        ISID: userData.ISID,
      };

      //Add value
      // data.completeID = completeID;
      // data.progressID = progressID;
      // data.ISID = userData.ISID;
      PostData(data);
    } else {
      //remove check
      let data2 = {
        progressID: progressyID.progressID,
        completeID: completeyID,
        ISID: userData.ISID,
      };
      PostData(data2);
    }
  };

  const PostData = async (data: any) => {
    const response = await apiService.PostData(
      "api/Progress/EditProgress",
      data
    );
    fetchProgress();
  };

  useEffect(() => {
    fetchProgress();
    fetchComplete();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <div className="portlet portlet-table">
        <div className="portlet-header">
          <h3>
            <i className="fa fa-group"></i>
            Checklist
          </h3>
          <hr></hr>
        </div>

        <div className="portlet-content">
          <div className="table-responsive">
            {loading ? (
              <Loader></Loader>
            ) : (
              <table
                id="user-signups"
                className="table table-striped table-bordered table-checkable"
              >
                <thead>
                  <tr>
                    <th>Programme Bit</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {complete &&
                    complete.map((item, index) => (
                      <tr>
                        <td>
                          <a
                            className="btn btn-xs btn-default"
                            href="/admin/upload-profile.aspx"
                          >
                            {item.completeName}
                          </a>
                        </td>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox12"
                            value=""
                            defaultChecked={checkIfProgressExists(
                              progress,
                              item.completeID
                            )}
                            onChange={(e) =>
                              saveProductCategory(
                                e,
                                item.completeID,
                                getProgressID(progress, item.completeID)
                              )
                            }
                          ></input>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}

            <Progress progress={progress}></Progress>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Checklist;
