import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import Moment from "moment";
import { FormatDate } from "../../helpers";

function TemplatesAdmin(): ReactElement {
  const { userData } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [workouts, setWorkouts] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const apiService = new ApiService();

  const fetchWorkouts = async (e: any) => {
    setLoading(true);
    var response;
    response = await apiService.getAllWorkoutsTemplate(e);
    setWorkouts(response);
    setLoading(false);
    window.scrollTo(0, 0);
  };
  let navigate = useNavigate();

  let location = useLocation();

  const handleClick = (e: any, workoutID: any, templateID: any) => {
    let path;
    if (userData.trainerID == 1) {
      if (location.pathname.includes("Templates")) {
        path = `/Edit-Workout?id=${e}&workoutID=${workoutID}&template=${templateID}`;
        navigate(path);
      } else {
        path = `/Edit-Workout?id=${e}&workoutID=${workoutID}`;
        navigate(path);
      }
    }
  };

  const handleKeyword = (e: any) => {
    setSearchKeyword(e);
    const apiService = new ApiService();
    const fetchWorkoutsByKeyword = async () => {
      const response = await apiService.getAllTemplateWorkoutByKeywords(e);
      setWorkouts(response);
      setLoading(false);
    };
    fetchWorkoutsByKeyword();
  };

  useEffect(() => {
    fetchWorkouts(1);
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3 className="float-start">Templates</h3>
      <button
        onClick={() =>
          navigate(
            `/Edit-Workout${
              location.pathname.includes("Template") ? "?template=true" : null
            } `
          )
        }
        className="btn btn-success float-end"
      >
        Create a Template
      </button>
      <div className="clearfix"></div>
      <hr></hr>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Template Search</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter keyword"
            onChange={(e) => handleKeyword(e.target.value)}
          />
          <Form.Text className="text-muted">
            Type to search for an item
          </Form.Text>
        </Form.Group>
      </Form>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Template Name</th>
          </tr>
        </thead>
        <tbody>
          {workouts &&
            workouts.map((item, index) => (
              <tr
                onClick={() =>
                  handleClick(item.workID, item.workoutID, item.templateID)
                }
              >
                <th scope="row">{index + 1}</th>
                <td>
                  {item.workTitle} {item.templateID === 1 ? "(Template)" : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default TemplatesAdmin;
