import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FormatDate } from "../../helpers";
import parse from "html-react-parser";

interface Resources {
  resourceID: number;
  resourceTitle: string;
  resourceCategory: number;
  resourceDetails: string;
  trainerISID: number;
  resourceStatus: number;
  resourceDate: string;
  resourceURL: string;
  resourceGuid: string;
}

function ResourcesAdmin(): ReactElement {
  const { userData, setUserData } = useContext(AuthContext);

  const [resource, setResource] = useState<Resources>({
    resourceID: 0,
    resourceTitle: "",
    resourceCategory: 0,
    resourceDetails: "",
    trainerISID: 0,
    resourceStatus: 0,
    resourceDate: "",
    resourceURL: "",
    resourceGuid: "",
  });

  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<any[]>([]);
  const [resourceID, setResourceID] = useState(0);
  const [deets, setDeets] = useState("");

  const apiService = new ApiService();

  let navigate = useNavigate();

  const getValue = (e: any) => {
    setLoading(true);
    setResourceID(e);

    const id = e;
    const apiService = new ApiService();
    const fetchResourceByID = async () => {
      const response = await apiService.getResourceByID(id);
      setLoading(false);
      setResource(response);
      const deetos = parse(response.resourceDetails);
      setDeets(deetos.toString());
    };
    fetchResourceByID();
  };

  const fetchResources = async () => {
    setLoading(true);
    const response = await apiService.getAllResources();
    setResources(response);

    getValue(response[0].resourceID);
    setLoading(false);
  };
  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>
        Resources{" "}
        <button
          onClick={(e) => navigate("../Create-Resource")}
          className="btn btn-success"
        >
          Create Resource
        </button>
      </h3>
      <hr></hr>
      <div className="row">
        <div className="col-md-4">
          <ul className="list-group">
            {resources &&
              resources.map((item, index) => (
                <li
                  onClick={(e) => getValue(item.resourceID)}
                  style={{ cursor: "pointer" }}
                  className={
                    resourceID === item.resourceID
                      ? "active list-group-item"
                      : "inactive list-group-item"
                  }
                >
                  {item.resourceTitle}
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-8">
          <h3>{resource.resourceTitle}</h3>
          <hr></hr>{" "}
          <Link
            className="btn btn-success"
            to={`../Edit-Resource?id=${resource.resourceID}`}
          >
            Edit Resource
          </Link>
          <hr></hr> <p style={{ fontWeight: "bolder" }}></p>
          <p dangerouslySetInnerHTML={{ __html: deets }} />
        </div>
      </div>
    </div>
  );
}
export default ResourcesAdmin;
