import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import Vimeo from "@u-wave/react-vimeo";
import Loader from "../Loader";
import { FormatVimeoLink } from "../../helpers";

function Exercises(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState<any[]>([]);
  const { userData, setUserData } = useContext(AuthContext);

  const [exers, setExers] = useState<any[]>([]);

  const [searchVideos, setSearchVideos] = useState<any[]>([]);
  const [vimeoFolders, setVimeoFolders] = useState<any[]>([]);

  const [videoCategory, setVideoCategory] = useState<any[]>([]);
  const [videoCat, setVideoCat] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [inputState, setInputState] = useState("");

  const [videoCategoryName, setVideoCategoryName] = useState("");
  const apiService = new ApiService();

  // const fetchVideos = async () => {
  //   setLoading(true);
  //   const response = await apiService.getAllVideosByCatID(1);
  //   setVideos(response);
  //   setLoading(false);
  //   getValue(1, "Back");

  //   window.scrollTo(0, 0);
  // };
  const fetchVideoCategories = async () => {
    const response = await apiService.getAllVideosCategories();
    setVideoCategory(response);
    window.scrollTo(0, 0);
  };

  const getValue = (e: any, name: string) => {
    setLoading(true);
    setVideoCat(e);
    setSearchKeyword("");
    const id = e;
    setVideoCategoryName(name);
    const apiService = new ApiService();
    const fetchVideoCategoriesByID = async () => {
      const response = await apiService.getAllVideosByCatID(id);
      setLoading(false);
      setVideos(response);
    };
    fetchVideoCategoriesByID();
    setLoading(false);
  };

  const findIfVideoExists = (id: any) => {
    return exers.find((element: any) => {
      if (Number(element.videoID) === Number(id)) {
        return true;
      } else {
      }
    });
  };

  const checkIsUserVideo = (e: any) => {
    const videoID = FormatVimeoLink(e);

    return findIfVideoExists(videoID);
  };

  const getVimeoValue = (e: any, name: string) => {
    setVideos([]);
    setSearchVideos([]);

    setInputState("");
    setSearchKeyword("");
    setLoading(true);
    setVideoCategoryName("");
    setVideos([]);
    const id = e;
    const apiService = new ApiService();
    const fetchVimeoVideosByFolderID = async () => {
      const response = await apiService.getAllVimeoVideosByFolderID(id);
      setVideos(response.data);

      setLoading(false);

      setVideoCategoryName(name);
      if (document.getElementById("formBasicEmail") !== undefined) {
        const doc = document.getElementById("formBasicEmail");
        (doc as HTMLInputElement).value = "";
      }
    };
    fetchVimeoVideosByFolderID();
  };

  //get all works by userID
  const fetchAllExerByUserID = async (e: any) => {
    const response = await apiService.getAllExerByISID(e);
    setExers(response);
  };

  const getVimeoValueByKeyword = (e: any) => {
    setVideoCategoryName("");
    setLoading(true);
    setVideos([]);
    setSearchVideos([]);
    const id = e;

    const apiService = new ApiService();
    const fetchVimeoVideosByKeyword = async () => {
      const response = await apiService.getAllVimeoVideosByKeyword(id);
      setLoading(false);
      setSearchVideos(response.data);
    };
    fetchVimeoVideosByKeyword();
    setSearchKeyword("");
    setInputState("");
  };

  //get vimeo folders
  //

  const fetchVimeoFolders = async () => {
    const response = await apiService.getAllVimeoFolders();
    setVimeoFolders(response.data);
  };

  const handleKeyword = (e: any) => {
    setLoading(true);
    setSearchKeyword(e);
    const apiService = new ApiService();
    const fetchVideosByKeyword = async () => {
      const response = await apiService.getAllVideosByKeywords(e);
      setVideos(response);
      setLoading(false);
    };
    fetchVideosByKeyword();
  };

  useEffect(() => {
    // fetchVideos();
    // fetchVideoCategories();
    fetchVimeoFolders();
    fetchAllExerByUserID(userData.ISID);
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Your Exercise Library</h3>
      <small>
        New videos will appear when new programmes are added to your account
      </small>
      <hr></hr>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Exercise Search</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter keyword"
            defaultValue={inputState}
            onChange={(e) => getVimeoValueByKeyword(e.target.value)}
          />
          <Form.Text className="text-muted">
            Type to search for an item
          </Form.Text>
        </Form.Group>
      </Form>
      <div className="row">
        <div className="col-md-4">
          <ul className="list-group">
            {/* {videoCategory &&
              videoCategory.map((item, index) => (
                <li
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    getValue(item.videoCategoryID, item.videoCategoryName)
                  }
                  className={
                    videoCategoryName === item.videoCategoryName
                      ? "active list-group-item"
                      : "inactive list-group-item"
                  }
                >
                  {item.videoCategoryName}
                </li>
              ))} */}

            {vimeoFolders &&
              vimeoFolders.map((item, index) => (
                <li
                  style={{ cursor: "pointer" }}
                  className="inactive list-group-item"
                  onClick={(e) => getVimeoValue(item.uri, item.name)}
                >
                  {item.name}
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-8">
          <h3>
            {searchKeyword
              ? "Search results for " + searchKeyword
              : videoCategoryName}
          </h3>

          <ul className="list-group">
            {loading ? (
              <Loader></Loader>
            ) : (
              <div className="row">
                {/* {videos &&
                videos.map((item, index) => (
                  <div className="col-md-6">
                    <li className="list-group-item" style={{ width: "100" }}>
                      <div className="row">
                        <div className="col-md-12 p-2">
                          <Vimeo
                            video={item.vimeoID}
                            style={{ width: "100" }}
                            showTitle
                            responsive
                            className="mb-3"
                          ></Vimeo>
                          <span className="mt-3 text-uppercase">
                            <h5>{item.videoTitle}</h5>
                          </span>
                        </div>
                      </div>
                    </li>
                  </div>
                ))} */}

                {searchVideos.length > 0
                  ? searchVideos &&
                    searchVideos.map((item, index) => (
                      <div className="col-md-6">
                        <li
                          className="list-group-item"
                          style={{ width: "100" }}
                        >
                          <div className="row">
                            <div className="col-md-12 p-2">
                              <Vimeo
                                video={item.player_embed_url}
                                style={{ width: "100" }}
                                showTitle
                                responsive
                                className="mb-3"
                              ></Vimeo>

                              <span className="mt-3 text-uppercase">
                                <h5>{item.name}</h5>
                              </span>
                            </div>
                          </div>
                        </li>
                      </div>
                    ))
                  : videos &&
                    videos.map((item, index) =>
                      checkIsUserVideo(item.video.player_embed_url) ? (
                        <div className="col-md-6">
                          <li
                            className="list-group-item"
                            style={{ width: "100" }}
                          >
                            <div className="row">
                              <div className="col-md-12 p-2">
                                <Vimeo
                                  video={item.video.player_embed_url}
                                  style={{ width: "100" }}
                                  showTitle
                                  responsive
                                  className="mb-3"
                                ></Vimeo>

                                <span className="mt-3 text-uppercase">
                                  <h5>{item.video.name}</h5>
                                </span>
                              </div>
                            </div>
                          </li>
                        </div>
                      ) : null
                    )}
              </div>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Exercises;
