import moment from "moment";
import Cookie from "js-cookie";

export function CalculateTotal(carty: any) {
  let subtotal = 0;
  carty.map((cartProduct: any) => {
    subtotal += cartProduct.productQuantity! * cartProduct.price!;
  });
  return subtotal;
}

export function FormatVimeoLink(link: any) {
  let id = link.split("/").slice(-1)[0].split("?")[0];
  return id;
}

export function FormatDate(date: Date) {
  return moment(date).format("DD MMMM yyyy");
}

export function FormatDBSafeDate(date: Date) {
  const newDate = new Date(date);
  return moment(newDate).format("dd/mm/yyyy").toString();
}

export function getBearer() {
  let bearerToken;
  const cookieRetrieve = Cookie.get("token");
  if (cookieRetrieve) {
    bearerToken = Cookie.get("token");
    return bearerToken;
  }
}
