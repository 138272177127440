import React, { ReactElement, useEffect, useState } from "react";

import moment from "moment";

import { useLocation, Link, useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";

interface Recipe {
  foodID: number;
  foodTitle: string;
  trainerID: number;
  foodMethod: string;
  foodIngredients: string;
  foodCategory: number;
  foodTag: string;
  foodPrep: string;
}

function RecipeDetails(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const apiService = new ApiService();
  const [loading, setLoading] = useState(false);

  const [recipe, setRecipe] = useState<Recipe>({
    foodID: 0,
    foodTitle: "",
    trainerID: 0,
    foodMethod: "",
    foodIngredients: "",
    foodCategory: 0,
    foodTag: "",
    foodPrep: "",
  });

  const fetchRecipe = async (e: number) => {
    setLoading(true);
    const response = await apiService.getFoodByID(e);
    setRecipe(response);

    setLoading(false);
    fetchRecipe(response.isid);
  };

  useEffect(() => {
    fetchRecipe(Number(id));
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h1>{recipe.foodTitle}</h1>
      <hr></hr>
      <div className="row">
        <div className="col-md-4">
          <img
            className="img-thumbnail"
            src={
              window.location.origin +
              `/img/recipes/${recipe?.foodID}/${recipe?.foodID}.jpg`
            }
            style={{
              maxWidth: "100%",
            }}
            alt={recipe.foodTitle}
          ></img>
          {/* <p>
            {" "}
            <div dangerouslySetInnerHTML={{ __html: recipe.foodIngredients }} />
          </p> */}
        </div>

        <div className="col-md-8">
          <p>
            {" "}
            <div dangerouslySetInnerHTML={{ __html: recipe.foodIngredients }} />
          </p>
          <p>
            <div dangerouslySetInnerHTML={{ __html: recipe.foodMethod }} />
          </p>
        </div>
      </div>
    </div>
  );
}
export default RecipeDetails;
