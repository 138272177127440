import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import Vimeo from "@u-wave/react-vimeo";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AuthContext } from "../../context/authContext";
import { FormatDate } from "../../helpers";

interface Workout {
  workTitle: string;
  workID: number;
  workEquip: number;
  workTime: number;
  workIntro: string;
  workCatID: number;
  infID: number;
}

interface Programme {
  programmeID: number;
  workID: number;
  dateCreated: Date;
  ISID: number;
  A1: string;
  A2: string;
  B1: string;
  B2: string;
  C1: string;
  C2: string;
  D1: string;
  D2: string;
  Weeks: string;
  restA: string;
  restB: string;
  restC: string;
}

function SavedDetails(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [isViewable, setIsViewable] = useState(false);

  const [exer, setExer] = useState<any[]>([]);
  const { userData } = useContext(AuthContext);

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const workoutID = query.get("workoutID");
  const savedWorkout = query.get("s");
  const programmeID = query.get("p");
  const workID = query.get("workID");
  const dateCreated = query.get("date");
  let navigate = useNavigate();

  const [workoutHistory, setWorkoutHistory] = useState([
    {
      workID: id,
      exerID: 0,
      ISID: userData.ISID,
      strData: "",
      workoutID: workoutID,
      programmeID: 0,
    },
  ]);

  const handleInputChange = (index: any, event: any, exerID: any) => {
    const values: any = [...custom];

    if (custom[index] === undefined) {
      values.push({
        workID: id,
        exerID: exerID,
        ISID: userData.ISID,
        setsreps: "",
        workoutID: workoutID,
        programmeID: programmeID,
      });
    }

    values[index]["setsreps"] = event.target.value;
    setCustom(values);
  };

  const [workout, setWorkout] = useState<Workout>({
    workTitle: "",
    workID: 0,
    workEquip: 0,
    workTime: 0,
    workIntro: "",
    workCatID: 0,
    infID: 0,
  });

  const [custom, setCustom] = useState([
    {
      customID: 0,
      exerID: 0,
      ISID: userData.ISID,
      setsreps: "",
      workoutID: workoutID,
    },
  ]);

  const [programme, setProgramme] = useState<Programme>({
    programmeID: 0,
    workID: Number(id),
    dateCreated: new Date(),
    ISID: userData.ISID,
    A1: "",
    A2: "",
    B1: "",
    B2: "",
    C1: "",
    C2: "",
    D1: "",
    D2: "",
    Weeks: "",
    restA: "",
    restB: "",
    restC: "",
  });

  const apiService = new ApiService();

  const fetchWorkoutByID = async (e: number) => {
    //check if this is the users workout
    setLoading(true);
    const response = await apiService.getWorkoutByID(e);

    setWorkout(response);
    setLoading(false);
  };

  const checkWorkoutByID = async (e: number) => {
    //check if this is the users workout
    setLoading(true);
    const response = await apiService.getTheWorkoutsByUserID(e);

    if (response.isid === userData.ISID || userData.trainerID === 1) {
      setIsViewable(true);
    } else {
    }
    setLoading(false);
  };

  const fetchExerByWorkID = async (e: number) => {
    setLoading(true);
    const response = await apiService.getAllExerByWorkID(e);
    setExer(response);
    setLoading(false);
  };

  const deleteWorkout = async () => {
    const response = await apiService.DeleteSavedProgramme(Number(programmeID));
    if (response === 1) {
      navigate("/Saved-Workouts");
    } else {
    }
  };

  const PostWorkoutData = async () => {
    const programmeData = {
      workID: Number(id),
      dateCreated: new Date(),
      ISID: userData.ISID,
      A1: "asd",
      A2: "asd",
      B1: "asd",
      B2: "asd",
      C1: "asd",
      C2: "asd",
      D1: "asd",
      D2: "asd",
      Weeks: "asd",
      restA: "asd",
      restB: "asd",
      restC: "asd",
    };

    const response2 = await apiService.PostData(
      "api/Programme/AddProgramme",
      programmeData
    );

    const values: any = [...workoutHistory];
    values["programmeID"] = response2;
    setWorkoutHistory(values);

    const response = await apiService.PostWorkoutData(
      "api/WorkoutHistory/AddHistory",
      values
    );
    if (response === 1 && response2 === 1) {
      let path = `/Saved-Workouts`;
      navigate(path);
    } else {
    }
  };

  const fetchCustomByID = async (e: number) => {
    //check if this is the users workout
    setLoading(true);
    const response = await apiService.getCustomByID(e);
    setCustom(response);
    setLoading(false);
  };

  useEffect(() => {
    if (programmeID) {
      fetchWorkoutByID(Number(workID));
      fetchExerByWorkID(Number(workID));
      fetchCustomByID(Number(programmeID));
    } else {
      checkWorkoutByID(Number(workoutID));
      fetchWorkoutByID(Number(id));
      fetchExerByWorkID(Number(id));
      if (savedWorkout) {
      }
    }
  }, [userData.ISID]);
  return (
    <div>
      <div className="container p-5 mb-4 bg-light rounded-3">
        <h3>
          {savedWorkout ? "Saved: " : null}
          {workout.workTitle} {FormatDate(dateCreated as any)}
          <React.Fragment>
            <button
              onClick={(e) => deleteWorkout()}
              className="btn btn-sm btn-danger form-inline float-end"
            >
              Delete Workout
            </button>
          </React.Fragment>
        </h3>

        <hr></hr>

        {exer &&
          exer.map((item, index: number) => (
            <div className="row">
              <div className="col-md-5">
                <Vimeo
                  video={item.videoID}
                  style={{ width: "100" }}
                  showTitle
                  responsive
                  className="mb-3"
                ></Vimeo>
              </div>
              <div className="col-md-7 exerDetails">
                <h3>
                  <span className="c-red">{item.exerCode}</span>.{" "}
                  {item.exerName}
                </h3>
                <table className="table table-striped text-left">
                  <tr>
                    <td className="p2" width={"100%"}>
                      <h5>Sets / Reps / Rest</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {custom &&
                        custom.map((item2, index: number) =>
                          item2.exerID === item.exerID ? (
                            <i>{item2.setsreps}</i>
                          ) : null
                        )}
                    </td>
                  </tr>
                </table>

                {!savedWorkout ? (
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Enter Sets/Reps</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Record your sets/reps"
                        onChange={(event) =>
                          handleInputChange(index, event, item.exerID)
                        }
                      />
                    </Form.Group>
                  </Form>
                ) : null}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
export default SavedDetails;
