import React, { ReactElement, useState, useEffect, useContext } from "react";
import ChartistGraph from "react-chartist";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/authContext";
import { FormatDate } from "../../helpers";
import { ApiService } from "../../services/api.service";
import { Link } from "react-router-dom";

function HandicapTracker(trainerData: any): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const [chartData, setChartData] = useState<any[]>([]);
  const [labels, setLabels] = useState<any[]>([]);
  const [series, setSeries] = useState<any[]>([]);
  const { userData, setUserData } = useContext(AuthContext);

  const apiService = new ApiService();

  const fetchChartData = async () => {
    if (trainerData.isTrainer === true && trainerData.adminUserID) {
      const response = await apiService.getAllChartDataHandicap(
        trainerData.adminUserID
      );
      setChartData(response);
    } else {
      const response = await apiService.getAllChartDataHandicap(userData.ISID);
      setChartData(response);
    }
  };
  useEffect(() => {
    fetchChartData();
  }, [trainerData]);

  const onSubmit = async (data: any) => {
    data.ISID = userData.ISID;
    data.dateCreated = new Date(data.dateCreated);
    const response = await apiService.PostData(
      "api/Handicap/AddHandicap",
      data
    );
    reset();
    fetchChartData();
  };

  var data = {
    labels: chartData.map((d: any) => FormatDate(d.dateCreated)),
    series: [chartData.map((d: any) => d.handicapValue)],
  };

  var options = {
    high: Math.max.apply(0, chartData),
    low: Math.min.apply(0, chartData),
    axisX: {
      labelInterpolationFnc: function (value: number, index: number) {
        return index % 1 === 0 ? value : null;
      },
    },
    seriesBarDistance: 10,
    fullWidth: true,
    showArea: true,
    height: "300px",
  };

  var type = "Line";

  return (
    <div>
      <h3>Handicap Tracker</h3> <hr></hr>
      {/*@ts-ignore*/}
      <ChartistGraph data={data} options={options} type={type} />{" "}
      <div
        style={{
          textAlign: "right",
          marginTop: "15px",
          paddingRight: "15px",
        }}
      >
        <Link className="btn btn-success" to="/Handicap-Calendar">
          Update
        </Link>
      </div>
      <hr></hr>
    </div>
  );
}
export default HandicapTracker;
