import React, { useState, createContext, useEffect, useContext } from "react";
import { ApiService } from "../services/api.service";
import Cookie from "js-cookie";
import { UserModel } from "../types/user";
import { getUser, clearUser, buildUser } from "../services/auth.service";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext({} as any);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [userData, setUserData] = useState({} as any | null);

  useState<UserModel[] | null | string>();

  const apiService = new ApiService();

  useEffect(() => {
    const authUser = async () => {
      const cookieVal = Cookie.get("token");
      if (cookieVal !== undefined) {
        let user = buildUser(cookieVal);
        if (user === undefined) {
          setUserData({ userGuid: "" });

          <Navigate to="./login" replace />;
        } else {
          setUserData(user);
        }
      }
    };
    authUser();
  }, []);

  return (
    <AuthContext.Provider value={{ userData, setUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
