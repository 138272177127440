import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import Moment from "moment";
import { FormatDate } from "../../helpers";

function SavedWorkouts(): ReactElement {
  const { userData } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [workouts, setWorkouts] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const apiService = new ApiService();

  const fetchWorkouts = async (e: any) => {
    setLoading(true);
    var response;
    response = await apiService.getAllProgrammesByUserID(e);
    setWorkouts(response);

    setLoading(false);
    window.scrollTo(0, 0);
  };
  let navigate = useNavigate();

  const handleClick = (
    e: any,
    workoutID: any,
    programmeID: any,
    workID: any,
    dateCreated: any
  ) => {
    let path;
    if (userData.trainerID == 1) {
      path = `/Edit-Workout?id=${e}&workoutID=${workoutID}`;
      //edit
    } else {
      path = `/Saved-Details?id=${e}&workoutID=${workoutID}&workID=${workID}&s=true&p=${programmeID}&date=${dateCreated}`;
    }
    navigate(path);
  };

  useEffect(() => {
    fetchWorkouts(userData.ISID);
  }, [userData.ISID]);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3 className="float-start">Saved Workouts</h3>

      <div className="clearfix"></div>
      <hr></hr>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Programme Name</th>
            <th scope="col">Date Saved</th>
          </tr>
        </thead>
        <tbody>
          {workouts &&
            workouts.map((item, index) => (
              <tr
                onClick={() =>
                  handleClick(
                    item.workID,
                    item.workoutID,
                    item.programmeID,
                    item.workID,
                    item.dateCreated
                  )
                }
              >
                <th scope="row">{index + 1}</th>
                <td>{item.work.workTitle} </td>
                <td>{FormatDate(item.dateCreated)} </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default SavedWorkouts;
