import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FormatDate } from "../../helpers";
import parse from "html-react-parser";

interface Recipe {
  foodID: number;
  foodTitle: string;
  trainerID: number;
  foodMethod: string;
  foodIngredients: string;
  foodCategory: number;
  foodTag: string;
  foodPrep: string;
}

function RecipeAdmin(): ReactElement {
  const { userData, setUserData } = useContext(AuthContext);

  const [food, setFood] = useState<Recipe>({
    foodID: 0,
    foodTitle: "",
    trainerID: 0,
    foodMethod: "",
    foodIngredients: "",
    foodCategory: 0,
    foodTag: "",
    foodPrep: "",
  });

  const [loading, setLoading] = useState(false);
  const [foods, setFoods] = useState<any[]>([]);
  const [foodID, setFoodID] = useState(0);
  const [deets, setDeets] = useState("");

  const apiService = new ApiService();

  let navigate = useNavigate();

  const getValue = (e: any) => {
    setLoading(true);
    setFoodID(e);

    const id = parseInt(e);
    const apiService = new ApiService();
    const fetchFoodByID = async () => {
      const response = await apiService.getFoodByID(id);
      setLoading(false);
      setFood(response);
      const deetos = parse(response.foodMethod);
      setDeets(deetos.toString());
    };
    fetchFoodByID();
  };

  const fetchFoods = async () => {
    setLoading(true);
    const response = await apiService.getAllFood();
    setFoods(response);

    getValue(response[0].foodID);
    setLoading(false);
  };
  useEffect(() => {
    fetchFoods();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>
        Recipes{" "}
        <button
          onClick={(e) => navigate("../Edit-Recipe?id=0")}
          className="btn btn-success"
        >
          Create Recipe
        </button>
      </h3>
      <hr></hr>
      <div className="row">
        <div className="col-md-4">
          <ul className="list-group">
            {foods &&
              foods.map((item, index) => (
                <li
                  onClick={(e) => getValue(item.foodID)}
                  style={{ cursor: "pointer" }}
                  className={
                    foodID === item.foodID
                      ? "active list-group-item"
                      : "inactive list-group-item"
                  }
                >
                  {item.foodTitle}
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-8">
          <h3>{food.foodTitle}</h3>
          <hr></hr>{" "}
          <Link
            className="btn btn-success"
            to={`../Edit-Recipe?id=${food.foodID}`}
          >
            Edit Recipe
          </Link>
          <hr></hr>
          <img
            className="img-thumbnail"
            src={
              window.location.origin +
              `/img/recipes/${food?.foodID}/${food?.foodID}.jpg`
            }
            style={{
              maxWidth: "100%",
            }}
            alt={food.foodTitle}
          ></img>
          <p style={{ fontWeight: "bolder" }}></p>
        </div>
      </div>
    </div>
  );
}
export default RecipeAdmin;
