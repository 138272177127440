import React, { ReactElement, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { ApiService } from "../../services/api.service";
import { useLocation, useNavigate } from "react-router";
import { FormatDBSafeDate, FormatDate } from "../../helpers";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";

import { Controller } from "react-hook-form";
import { FloatingLabel, FormLabel } from "react-bootstrap";

function EditMessage(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  let navigate = useNavigate();

  interface Message {
    messageID: number;
    messageTitle: string;
    messageDetails: string;
    trainerISID: number;
    userID: number;
    messageStatus: number;
    messageDate: Date;
    messageURL: string;
    messageGuid: string;
  }

  const [message, setMessage] = useState<any>({
    messageID: 0,
    messageTitle: "",
    messageDetails: "",
    trainerISID: 0,
    userID: 0,
    messageStatus: 0,
    messageDate: null,
    messageURL: "",
    messageGuid: "",
  });

  const [startDate, setStartDate] = useState(new Date() as any | null);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const [users, setUsers] = useState<any[]>([]);
  const [usersID, setUserID] = useState("");

  const [savedMessage, setSavedMessage] = useState("");

  const [savedMessageStatus, setSavedMessageStatus] = useState("");

  /* @ts-ignore*/
  const editorRef = useRef();
  const log = () => {
    if (editorRef.current) {
    }
  };

  const handleInputChange = (event: any, formName: string) => {
    const values: any = { ...message };

    if (message === undefined) {
      values.push({
        messageID: 0,
        messageTitle: "",
        messageDetails: "",
        trainerISID: 10,
        userID: 0,
        messageStatus: 0,
        messageDate: "",
        messageURL: "",
        messageGuid: "",
      });
    }

    if (formName === "messageDetails") {
      const updatedValue = formName;
      values[updatedValue] = event;
    } else {
      const updatedValue = formName;
      values[updatedValue] = event.target.value;
    }

    if (formName === "messageStatus") {
      const updatedValue = formName;
      values[updatedValue] = parseInt(event.target.value);
    }

    setMessage(values);
  };

  const apiService = new ApiService();

  const onSubmit = (data: any) => {
    PostData(data);
  };

  const fetchMessageByID = async (id: number) => {
    const response = await apiService.getMessageByID(id);
    setMessage(response);
    setStartDate(new Date(response.messageDate));
  };

  const PostData = async (e: any) => {
    const values: any = { ...message };

    values.messageDate = startDate;
    values.userID = usersID;

    e.preventDefault();
    const response = await apiService.PostData(
      "api/Message/AddMessage",
      values
    );
    setMessage(values);
    setSavedMessage("Saved");
    setTimeout(() => {
      setSavedMessage("");
    }, 3000);
  };

  const fetchUsers = async () => {
    const response = await apiService.getAllUsers();
    setUsers(response);
  };

  useEffect(() => {
    fetchMessageByID(Number(id));
    fetchUsers();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Edit Message</h3>
      <hr></hr>

      <form>
        <div className="row">
          {/* FIRST COL */}
          <div className="col-md-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message Title"
                id="form3Example4"
                defaultValue={message.messageTitle}
                onChange={(e) => handleInputChange(e, "messageTitle")}
              />
            </div>
            {/* @ts-ignore*/}
            <Editor
              apiKey="h2z52jer61ylbqfag18e0gn0hxvharfdby44xefhs3qyww1g"
              onInit={(evt, editor) =>
                editorRef.current != undefined ? editor : null
              }
              onEditorChange={(e) => handleInputChange(e, "messageDetails")}
              value={message.messageDetails}
              init={{
                height: 500,
                entity_encoding: "named",
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message URL"
                id="form3Example4"
              />
            </div>

            {/* @ts-ignore*/}
            <DatePicker
              className="form-control"
              selected={startDate}
              dateFormat={"dd/MM/yyyy"}
              onChange={(dateSelected) => setStartDate(dateSelected)}
            />
            <div className="form-group">
              <div className="custom_select">
                <select
                  className="form-control select-active"
                  onChange={(e) => handleInputChange(e, "messageStatus")}
                  defaultValue={message.messageStatus}
                >
                  <option selected={0 === message.messageStatus} value={0}>
                    Message Status...
                  </option>
                  <option selected={1 === message.messageStatus} value={1}>
                    Published
                  </option>

                  <option selected={2 === message.messageStatus} value={2}>
                    Draft
                  </option>
                  <option selected={3 === message.messageStatus} value={3}>
                    Template
                  </option>
                </select>{" "}
              </div>
            </div>

            <div className="form-group">
              <div className="custom_select">
                <select
                  className="form-control select-active"
                  onChange={(e) => setUserID(e.target.value)}
                >
                  <option disabled value="0">
                    Select user...
                  </option>
                  {users &&
                    users.map((item: any) => (
                      <option
                        selected={message.userID === item.userID}
                        value={item.userID}
                      >
                        {item.userFirstname} {item.userLastname}
                      </option>
                    ))}
                </select>{" "}
              </div>
            </div>
            <hr></hr>
            <button
              type="submit"
              className="btn btn-success hover-up"
              name="login"
              onClick={PostData}
            >
              Save Message
            </button>
            {savedMessage !== "" ? (
              <span
                style={{
                  backgroundColor: "green",
                  padding: 10,
                  borderRadius: 10,
                  color: "#fff",
                  width: "100%",
                  marginLeft: 20,
                }}
              >
                {savedMessage}
              </span>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
}
export default EditMessage;
