import React, { ReactElement, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { ApiService } from "../../services/api.service";
import { useLocation } from "react-router";
import { FormatDBSafeDate, FormatDate } from "../../helpers";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";
import htmr from "htmr";
import { Controller } from "react-hook-form";
import parse from "html-react-parser";
import { encode } from "html-entities";
interface Resources {
  resourceID: number;
  resourceTitle: string;
  resourceCategory: number;
  resourceDetails: string;
  trainerISID: number;
  resourceStatus: number;
  resourceDate: string;
  resourceURL: string;
}

function CreateResource(): ReactElement {
  const [startDate, setStartDate] = useState(new Date() as any | null);

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  /* @ts-ignore*/
  const editorRef = useRef();
  const log = () => {
    if (editorRef.current) {
    }
  };

  const [resource, setResource] = useState<Resources>({
    resourceID: 0,
    resourceTitle: "",
    resourceCategory: 0,
    resourceDetails: "",
    trainerISID: 10,
    resourceStatus: 0,
    resourceDate: "",
    resourceURL: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const apiService = new ApiService();

  const handleInputChange = (event: any, formName: string) => {
    const values: any = { ...resource };

    if (resource === undefined) {
      values.push({
        resourceID: 0,
        resourceTitle: "",
        resourceCategory: 0,
        resourceDetails: "",
        trainerISID: 0,
        resourceStatus: 0,
        resourceDate: "",
        resourceURL: "",
      });
    }
    if (formName === "resourceDetails") {
      const updatedValue = formName;
      values[updatedValue] = event;
    } else {
      const updatedValue = formName;
      values[updatedValue] = event.target.value;
    }

    setResource(values);
  };

  const fetchResourceByID = async (id: number) => {
    const response = await apiService.getResourceByID(id);
    setResource(response);
  };

  const PostResourceData = async (e: any) => {
    e.preventDefault();
    const values: any = { ...resource };
    values.resourceDate = startDate;
    e.preventDefault();
    const response = await apiService.PostData(
      "api/Resource/AddResource",
      values
    );
  };

  useEffect(() => {
    fetchResourceByID(Number(id));
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Create Resource: {resource.resourceTitle}</h3>
      <hr></hr>

      <form>
        <div className="row">
          {/* FIRST COL */}
          <div className="col-md-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Resource Title"
                id="form3Example4"
                defaultValue={resource.resourceTitle}
                onChange={(e) => handleInputChange(e, "resourceTitle")}
              />
            </div>

            {/* @ts-ignore*/}
            <Editor
              apiKey="h2z52jer61ylbqfag18e0gn0hxvharfdby44xefhs3qyww1g"
              onInit={(evt, editor) =>
                editorRef.current != undefined ? editor : null
              }
              onEditorChange={(e) => handleInputChange(e, "resourceDetails")}
              value={resource.resourceDetails}
              init={{
                height: 500,
                entity_encoding: "named",
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Resource URL"
                id="form3Example4"
                onChange={(e) => handleInputChange(e, "resourceURL")}
              />
            </div>
            {/* @ts-ignore*/}
            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={(dateSelected) =>
                handleInputChange(dateSelected, "messageDate")
              }
            />
            <div className="form-group">
              <div className="custom_select">
                <select
                  className="form-control select-active"
                  onChange={(e) => handleInputChange(e, "resourceStatus")}
                >
                  <option selected={0 == resource.resourceStatus} value={0}>
                    Status...
                  </option>
                  <option selected={1 == resource.resourceStatus} value={1}>
                    Published
                  </option>

                  <option selected={2 == resource.resourceStatus} value={2}>
                    Draft
                  </option>
                </select>{" "}
              </div>
            </div>
            <hr></hr>
            <button
              type="submit"
              className="btn btn-success hover-up"
              name="login"
              onClick={PostResourceData}
            >
              Save Resource
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
export default CreateResource;
