import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FormatDate } from "../../helpers";

interface Message {
  messageID: number;
  messageTitle: string;
  messageDetails: string;
  trainerISID: number;
  userID: number;
  messageStatus: number;
  messageDate: string;
  messageURL: string;
  messageGuid: string;
}

function MessagesTemplates(): ReactElement {
  const [message, setMessage] = useState<Message>({
    messageID: 0,
    messageTitle: "",
    messageDetails: "",
    trainerISID: 0,
    userID: 0,
    messageStatus: 0,
    messageDate: "",
    messageURL: "",
    messageGuid: "",
  });

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [messageID, setMessageID] = useState(0);

  const apiService = new ApiService();

  let navigate = useNavigate();

  const getValue = (e: any) => {
    setLoading(true);
    setMessageID(e);

    const id = e;
    const apiService = new ApiService();
    const fetchMessageByID = async () => {
      const response = await apiService.getMessageByID(id);
      setLoading(false);
      setMessage(response);
    };
    fetchMessageByID();
  };

  const deleteMessageTemplate = async (e: number) => {
    const response = await apiService.DeleteMessage(e);

    fetchMessages();
  };

  const fetchMessages = async () => {
    setLoading(true);
    const response = await apiService.getAllMessages();
    setMessages(response);
    //getValue(response[0].messageID);
    setLoading(false);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Message Templates</h3>
      <hr></hr>
      <div className="row">
        <div className="col-md-4">
          <ul className="list-group">
            {messages &&
              messages.map((item, index) =>
                item.messageStatus === 3 ? (
                  <li
                    onClick={(e) => getValue(item.messageID)}
                    style={{ cursor: "pointer" }}
                    className={
                      messageID === item.messageID
                        ? "active list-group-item"
                        : "inactive list-group-item"
                    }
                  >
                    {item.messageTitle}
                  </li>
                ) : null
              )}
          </ul>
          <hr></hr>
          <button
            onClick={(e) => navigate("../Create-Message-Template")}
            className="btn btn-success"
          >
            Create Template
          </button>
        </div>
        <div className="col-md-8">
          <h3>{message.messageTitle}</h3>
          <hr></hr>

          <p dangerouslySetInnerHTML={{ __html: message.messageDetails }} />
          <hr></hr>

          {messageID ? (
            <React.Fragment>
              <Link
                className="btn btn-success"
                to={`../Edit-Message-Template?id=${message.messageID}`}
              >
                Edit Template
              </Link>
              <button
                className="btn btn-success"
                onClick={(e) => deleteMessageTemplate(message.messageID)}
              >
                Delete Template
              </button>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default MessagesTemplates;
