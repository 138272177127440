import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiService } from "../../services/api.service";
import { useLocation } from "react-router";
import { FormatDBSafeDate, FormatDate } from "../../helpers";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";
import { Controller } from "react-hook-form";

declare var Blob: {
  prototype: Blob;
  new (): Blob;
  new (request: any, mime: string): Blob;
};

interface Recipe {
  foodID: number;
  foodTitle: string;
  trainerID: number;
  foodMethod: string;
  foodIngredients: string;
  foodCategory: number;
  foodTag: number;
  foodPrep: number;
}

function EditRecipe(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [fileExists, setFileExists] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadResponse, setUploadResponse] = useState("");

  /* @ts-ignore*/
  const editorRef = useRef();
  const log = () => {
    if (editorRef.current) {
    }
  };

  const [food, setFood] = useState<Recipe>({
    foodID: 0,
    foodTitle: "",
    trainerID: 0,
    foodMethod: "",
    foodIngredients: "",
    foodCategory: 0,
    foodTag: 0,
    foodPrep: 0,
  });

  const apiService = new ApiService();

  const onFileChange = (e: any) => {
    // Update the state
    setSelectedFile(e.target.files[0]);
  };

  const onFileUpload = (e: any) => {
    e.preventDefault();
    setUploading(true);
    // Create an object of formData

    var data = {
      Image: selectedFile,
    };

    // // Update the formData object
    // formData.append("Image", selectedFile);
    // formData.append("Name", "1");

    let formData = new FormData();
    formData.append("file", selectedFile, food.foodID.toString());
    PostImage(formData);
  };

  const PostImage = async (data: any) => {
    setUploading(true);
    const response = await apiService.PostFile("api/food/UploadFile", data);

    if (response.ok) {
      setUploadResponse("Your Home Page Image has been uploaded");
      setUploadComplete(true);
    } else {
      setUploadResponse("Your Home Page Image has not been uploaded");
    }
    setUploading(false);
  };

  const PostFoodData = async (e: any) => {
    e.preventDefault();
    const response = await apiService.PostData("../api/Food/AddFood", food);
    if (response === 1) {
      fetchFoodByID(response.foodID);
    }
  };

  const handleInputChange = (event: any, formName: string) => {
    const values: any = { ...food };

    if (food === undefined) {
      values.push({
        foodID: 0,
        foodTitle: "",
        trainerID: 0,
        foodMethod: "",
        foodIngredients: "",
        foodCategory: 0,
        foodTag: "",
        foodPrep: 0,
      });
    }

    if (formName === "foodMethod" || formName === "foodIngredients") {
      const updatedValue = formName;
      values[updatedValue] = event;
    } else {
      const updatedValue = formName;
      values[updatedValue] = event.target.value;
    }

    setFood(values);
  };

  const fetchFoodByID = async (id: number) => {
    const response = await apiService.getFoodByID(id);
    setFood(response);
  };

  const PostData = async (data: any) => {
    //const response = await apiService.PostData("api/Food/AddFood", data);
  };

  useEffect(() => {
    if (id) {
      fetchFoodByID(Number(id));
    }
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Edit Recipe: {food.foodTitle}</h3>
      <hr></hr>

      <form>
        <div className="row">
          {/* FIRST COL */}
          <div className="col-md-12">
            {food.foodID ? (
              <div>
                <img
                  className="img-thumbnail"
                  src={
                    window.location.origin +
                    `/img/recipes/${food?.foodID}/${food?.foodID}.jpg`
                  }
                  style={{
                    maxWidth: "100%",
                  }}
                  alt={food.foodTitle}
                ></img>

                <input
                  className="inputfile"
                  type="file"
                  onChange={onFileChange}
                />
                <button className="btn btn-success" onClick={onFileUpload}>
                  {uploading ? (
                    <span>Uploading</span>
                  ) : (
                    <span>Upload an image</span>
                  )}
                </button>

                <hr></hr>
              </div>
            ) : null}

            <div className="form-group">
              <input
                type="text"
                name="foodTitle"
                className="form-control"
                placeholder="Recipe Title"
                id="form3Example4"
                defaultValue={food.foodTitle}
                onChange={(e) => handleInputChange(e, "foodTitle")}
              />
            </div>

            <p>Recipe Ingredients</p>
            {/* @ts-ignore*/}
            <Editor
              apiKey="h2z52jer61ylbqfag18e0gn0hxvharfdby44xefhs3qyww1g"
              onInit={(evt, editor) =>
                editorRef.current != undefined ? editor : null
              }
              value={food.foodIngredients}
              onEditorChange={(e) => handleInputChange(e, "foodIngredients")}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            <p>Recipe Method</p>
            {/* @ts-ignore*/}
            <Editor
              apiKey="h2z52jer61ylbqfag18e0gn0hxvharfdby44xefhs3qyww1g"
              onInit={(evt, editor) =>
                editorRef.current != undefined ? editor : null
              }
              value={food.foodMethod}
              onEditorChange={(e) => handleInputChange(e, "foodMethod")}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />

            {/* @ts-ignore*/}

            {/* <div className="form-group">
              <textarea
                rows={10}
                id="form3Example4"
                className="form-control"
                placeholder="Ingredients"
                defaultValue={food.foodIngredients}
                {...register("foodIngredients", {
                  required: "Ingredients is required",
                })}
              />
              {errors.foodIngredients ? (
                <>
                  {errors.foodIngredients.type === "required" && (
                    <span className="small error">
                      {errors.foodIngredients.message}
                    </span>
                  )}
                </>
              ) : null}
            </div> */}

            <div className="form-group" style={{ marginTop: "20px" }}>
              <div className="custom_select">
                <select
                  className="form-control select-active"
                  defaultValue={food.foodCategory}
                  onChange={(e) => handleInputChange(e, "foodCategory")}
                >
                  <option selected={0 == food.foodCategory} value={0}>
                    Please Select
                  </option>
                  <option selected={1 == food.foodCategory} value={1}>
                    Breakfast
                  </option>
                  <option selected={2 == food.foodCategory} value={2}>
                    Lunch
                  </option>
                  <option selected={3 == food.foodCategory} value={3}>
                    Dinner
                  </option>
                  <option selected={4 == food.foodCategory} value={4}>
                    Golf Course Snacks
                  </option>
                  <option selected={7 == food.foodCategory} value={7}>
                    Desserts/Cheat Meals
                  </option>
                </select>
              </div>
            </div>

            <hr></hr>
            <button
              type="submit"
              className="btn btn-success hover-up"
              name="login"
              onClick={PostFoodData}
            >
              Save Recipe
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
export default EditRecipe;
