import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { FormatDate } from "../../helpers";

function Clients(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const apiService = new ApiService();

  const fetchUsers = async () => {
    setLoading(true);
    const response = await apiService.getAllUsers();
    setUsers(response);
    setLoading(false);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const navigate = useNavigate();

  function handleClick(item: any) {
    navigate("/client-details?id=" + item);
  }

  const handleKeyword = (e: any) => {
    setSearchKeyword(e);
    const apiService = new ApiService();
    const fetchUsersByKeyword = async () => {
      const response = await apiService.getAllUsersByKeywords(e);
      setUsers(response);
      setLoading(false);
    };
    fetchUsersByKeyword();
  };

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Clients</h3>
      <hr></hr>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Client Search</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter keyword"
            onChange={(e) => handleKeyword(e.target.value)}
          />
          <Form.Text className="text-muted">
            Type to search for an client
          </Form.Text>
        </Form.Group>
      </Form>

      <ul className="list-group">
        {users &&
          users.map((item, index) => (
            <li
              onClick={() => handleClick(item.userGUID)}
              // onClick={(e) => getValue(item.resourceID)}
              style={{ cursor: "pointer" }}
              className="list-group-item"
            >
              {item.userFirstname} {item.userLastname}
              <span className="text-right" style={{ float: "right" }}>
                ({FormatDate(item.dateCreated)})
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
}
export default Clients;
