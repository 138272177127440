import React, { Component, ErrorInfo, ReactNode, useState } from "react";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorReport: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorReport: "",
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorReport: "" };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    return { errorReport: errorInfo };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="container text-center">
          <div className="row flex-row-reverse">
            <div className="col-lg-12">
              <div className="product-detail accordion-detail">
                <div className="row mb-50">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1>Sorry.. there was an error</h1>
                    <p>{"Error " + this.state.errorReport}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
