import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import Vimeo from "@u-wave/react-vimeo";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AuthContext } from "../../context/authContext";
import DatePicker from "react-datepicker";

interface Workout {
  workTitle: string;
  workID: number;
  workEquip: number;
  workTime: number;
  workIntro: string;
  workCatID: number;
  infID: number;
}

interface Custom {
  customID: number;
  ISID: number;
  setsreps: string;
  exerID: number;
  workoutID: number;
}

interface Programme {
  programmeID: number;
  workID: number;
  dateCreated: Date;
  ISID: number;
  A1: string;
  A2: string;
  B1: string;
  B2: string;
  C1: string;
  C2: string;
  D1: string;
  D2: string;
  Weeks: string;
  restA: string;
  restB: string;
  restC: string;
}

function WorkoutDetails(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [isViewable, setIsViewable] = useState(false);

  const [theDate, setTheDate] = useState(new Date() as any | null);

  const [exer, setExer] = useState<any[]>([]);
  const { userData } = useContext(AuthContext);

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const workoutID = query.get("workoutID");

  const savedWorkout = query.get("s");
  const programmeID = query.get("p");
  let navigate = useNavigate();

  const [workoutHistory, setWorkoutHistory] = useState([
    {
      workID: id,
      exerID: 0,
      ISID: userData.ISID,
      strData: "",
      workoutID: workoutID,
      programmeID: 0,
    },
  ]);

  const [custom, setCustom] = useState([
    {
      customID: 0,
      exerID: 0,
      ISID: Number(userData.ISID),
      setsreps: "",
      workoutID: Number(workoutID),
    },
  ]);

  const handleInputChange = (index: any, event: any, exerID: any) => {
    const values: any = [...custom];

    if (custom[index] === undefined) {
      values.push({
        customID: 0,
        exerID: Number(exerID),
        ISID: Number(userData.ISID),
        setsreps: "",
        workoutID: Number(workoutID),
      });
    }

    values[index]["setsreps"] = event.target.value;
    values[index]["exerID"] = exerID;
    setCustom(values);
  };

  const [workout, setWorkout] = useState<Workout>({
    workTitle: "",
    workID: 0,
    workEquip: 0,
    workTime: 0,
    workIntro: "",
    workCatID: 0,
    infID: 0,
  });

  const [programme, setProgramme] = useState<Programme>({
    programmeID: 0,
    workID: Number(id),
    dateCreated: new Date(theDate),
    ISID: Number(userData.ISID),
    A1: "",
    A2: "",
    B1: "",
    B2: "",
    C1: "",
    C2: "",
    D1: "",
    D2: "",
    Weeks: "",
    restA: "",
    restB: "",
    restC: "",
  });

  const apiService = new ApiService();

  const setDate = async (e: any) => {
    setTheDate(e);
  };

  const fetchWorkoutByID = async (e: number) => {
    //check if this is the users workout
    setLoading(true);
    const response = await apiService.getWorkoutByID(e);
    setWorkout(response);
    setLoading(false);
  };

  const fetchCustomByID = async (e: number) => {
    //check if this is the users workout
    setLoading(true);
    const response = await apiService.getCustomByID(e);
    setCustom(response);
    setLoading(false);
  };

  const checkWorkoutByID = async (e: number) => {
    //check if this is the users workout
    setLoading(true);
    const response = await apiService.getTheWorkoutsByUserID(e);

    if (response.isid === userData.ISID || userData.trainerID === 1) {
      setIsViewable(true);
    } else {
    }
    setLoading(false);
  };

  const fetchExerByWorkID = async (e: number) => {
    setLoading(true);
    const response = await apiService.getAllExerByWorkID(e);
    setExer(response);

    setLoading(false);
  };

  const PostWorkoutData = async () => {
    const programmeData = {
      workID: Number(id),
      dateCreated: new Date(theDate),
      ISID: userData.ISID,
      A1: "asd",
      A2: "asd",
      B1: "asd",
      B2: "asd",
      C1: "asd",
      C2: "asd",
      D1: "asd",
      D2: "asd",
      Weeks: "asd",
      restA: "asd",
      restB: "asd",
      restC: "asd",
    };

    const response2 = await apiService.PostData(
      "api/Programme/AddProgramme",
      programmeData
    );

    const values: any = [...custom];

    values.map((item: any, index: number) => {
      values[index].workoutID = response2;
    });

    setCustom(values);

    const response = await apiService.PostData("api/Custom/AddCustom", values);
    if (response === 1) {
      let path = `/Saved-Workouts`;
      navigate(path);
    } else {
    }
  };

  useEffect(() => {
    if (programmeID) {
      checkWorkoutByID(Number(programmeID));
      fetchWorkoutByID(Number(programmeID));
      //  fetchCustomByID(Number(workoutID));
    } else {
      checkWorkoutByID(Number(workoutID));
      fetchWorkoutByID(Number(id));
      fetchExerByWorkID(Number(id));
      if (savedWorkout) {
      }
    }
  }, [userData.ISID]);
  return (
    <div>
      {isViewable ? (
        <div className="container p-5 mb-4 bg-light rounded-3">
          <h3>
            {savedWorkout ? "Saved: " : null}
            {workout.workTitle}
          </h3>

          <hr></hr>

          {exer &&
            exer.map((item, index: number) => (
              <div className="row">
                <div className="col-md-5">
                  <Vimeo
                    video={item.videoID}
                    style={{ width: "100" }}
                    showTitle
                    responsive
                    className="mb-3"
                  ></Vimeo>
                </div>
                <div className="col-md-7 exerDetails">
                  <h3>
                    <span className="c-red">{item.exerCode}</span>.{" "}
                    {item.exerName}
                  </h3>
                  <table className="table table-striped text-left">
                    <tr>
                      <td className="p2" width={"10%"}>
                        Sets
                      </td>
                      <td>{item.exerSet}</td>
                    </tr>
                    <tr>
                      <td className="p2">Reps</td>
                      <td>{item.exerRep}</td>
                    </tr>
                    <tr>
                      <td className="p2">Rest</td>
                      <td>{item.exerRest}</td>
                    </tr>
                  </table>

                  {!savedWorkout ? (
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter Sets/Reps</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Record your sets/reps"
                          onChange={(event) =>
                            handleInputChange(index, event, item.exerID)
                          }
                        />
                      </Form.Group>
                    </Form>
                  ) : null}
                </div>
              </div>
            ))}

          {!savedWorkout ? (
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter Date</Form.Label>
                {/* @ts-ignore*/}
                <DatePicker
                  className="form-control"
                  selected={theDate}
                  onChange={(dateSelected) => setDate(dateSelected)}
                />
              </Form.Group>
            </Form>
          ) : null}
          <button
            onClick={PostWorkoutData}
            className="btn btn-success form-inline"
          >
            Save Your Workout Data
          </button>
        </div>
      ) : (
        <div className="container p-5 mb-4 bg-light rounded-3">
          <h3>This programme is not viewable</h3>
        </div>
      )}
    </div>
  );
}
export default WorkoutDetails;
