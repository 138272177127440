import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FormatDate } from "../../helpers";

interface Message {
  messageID: number;
  messageTitle: string;
  messageDetails: string;
  trainerISID: number;
  userID: number;
  messageStatus: number;
  messageDate: string;
  messageURL: string;
  messageGuid: string;
  user: {
    userFirstname: string;
  };
}

function Messages(): ReactElement {
  const { userData, setUserData } = useContext(AuthContext);

  const [message, setMessage] = useState<Message>({
    messageID: 0,
    messageTitle: "",
    messageDetails: "",
    trainerISID: 0,
    userID: 0,
    messageStatus: 0,
    messageDate: "",
    messageURL: "",
    messageGuid: "",
    user: {
      userFirstname: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [messageID, setMessageID] = useState(0);

  const apiService = new ApiService();

  let navigate = useNavigate();

  const getValue = (e: any) => {
    setLoading(true);
    setMessageID(e);

    const id = e;
    const apiService = new ApiService();
    const fetchMessageByID = async () => {
      const response = await apiService.getMessageByID(id);
      setLoading(false);
      setMessage(response);
    };
    fetchMessageByID();
  };

  const fetchMessages = async () => {
    setLoading(true);
    const response = await apiService.getAllMessagesByID(userData.userID);
    setMessages(response);

    getValue(response[0].messageID);
    setLoading(false);
  };
  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Message Centre</h3>
      <hr></hr>
      <div className="row">
        <div className="col-md-4">
          <ul className="list-group">
            {messages &&
              messages.map((item, index) => (
                <li
                  onClick={(e) => getValue(item.messageID)}
                  style={{ cursor: "pointer" }}
                  className={
                    messageID === item.messageID
                      ? "active list-group-item"
                      : "inactive list-group-item"
                  }
                >
                  {item.messageTitle}
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-8">
          <h3>{message.messageTitle}</h3>
          <hr></hr>{" "}
          <p style={{ fontWeight: "bolder" }}>
            <img
              src={`/img/avatars/${message.trainerISID}/${message.trainerISID}.jpg`}
              alt="mdo"
              width="32"
              height="32"
              className="rounded-circle"
            ></img>{" "}
            {message.user.userFirstname} |{" "}
            {FormatDate(new Date(message.messageDate))}
          </p>
          <hr></hr>
          <p dangerouslySetInnerHTML={{ __html: message.messageDetails }} />
        </div>
      </div>
    </div>
  );
}
export default Messages;
