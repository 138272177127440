import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import Cookie from "js-cookie";
import { useNavigate } from "react-router";

function TopHeader(): ReactElement {
  const { userData, setUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const logOut = async (e: any) => {
    e.preventDefault();

    Cookie.remove("token");
    Cookie.remove("userID");
    Cookie.remove("cart");
    setUserData({ userGuid: "" });
    navigate("/");
  };

  return (
    <nav
      className="py-2 text-bg-dark"
      style={{ borderBottom: "3px solid #c6fe3b" }}
    >
      <div className="container d-flex flex-wrap">
        <ul className="nav me-auto">
          <li className="nav-item">
            <Link className="nav-link link-dark px-2" to="/">
              Home
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link link-dark px-2" to="Exercises">
              Exercise Library
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link link-dark px-2" to="Resources">
              Resources
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link link-dark px-2" to="Saved-Workouts">
              Workout History
            </Link>
          </li>
          {userData.trainerID === 1 ? (
            <li className="nav-item">
              <div className="dropdown text-end">
                <a
                  className="nav-link link-dark px-2 dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                >
                  Admin
                </a>
                <ul className="dropdown-menu text-small">
                  <li>
                    <Link to="./Workouts-Admin" className="dropdown-item">
                      All Workouts
                    </Link>
                  </li>
                  <li>
                    <Link to="./Templates-Admin" className="dropdown-item">
                      Templates
                    </Link>
                  </li>
                  <li>
                    <Link to="./Clients" className="dropdown-item">
                      Clients
                    </Link>
                  </li>
                  <li>
                    <Link to="./Messages-Admin" className="dropdown-item">
                      Messages
                    </Link>
                  </li>
                  <li>
                    <Link to="./Messages-Templates" className="dropdown-item">
                      Message Templates
                    </Link>
                  </li>
                  <li>
                    <Link to="./Resources-Admin" className="dropdown-item">
                      Resources
                    </Link>
                  </li>
                  <li>
                    <Link to="./Recipes-Admin" className="dropdown-item">
                      Recipes
                    </Link>
                  </li>

                  <li>
                    <hr className="dropdown-divider"></hr>
                  </li>
                  <li className="dropdown-item" onClick={(e) => logOut(e)}>
                    Sign out
                  </li>
                </ul>
              </div>
            </li>
          ) : null}
        </ul>
        <ul className="nav">
          <li className="nav-item">
            <span className="nav-link link-dark px-2">
              Hello {userData.displayName}
            </span>
          </li>
          <div className="dropdown text-end mt-1">
            <span
              className="d-block link-dark text-decoration-none dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ cursor: "pointer" }}
            >
              <img
                src={`/img/avatars/${userData.ISID}/${userData.ISID}.jpg`}
                alt="mdo"
                width="32"
                height="32"
                className="rounded-circle"
              ></img>
            </span>
            <ul className="dropdown-menu text-small">
              <li>
                <Link
                  to={`./client-details?id=${userData.userGuid}`}
                  className="dropdown-item"
                >
                  Your Profile
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider"></hr>
              </li>
              <li>
                <a onClick={(e) => logOut(e)} className="dropdown-item">
                  Sign out
                </a>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </nav>
  );
}

export default TopHeader;
