import React, { ReactElement, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { ApiService } from "../../services/api.service";
import { useLocation } from "react-router";
import { FormatDBSafeDate, FormatDate } from "../../helpers";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";

function EditMessageTemplate(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  interface Message {
    messageID: number;
    messageTitle: string;
    messageDetails: string;
    trainerISID: number;
    userID: number;
    messageStatus: number;
    messageDate: string;
    messageURL: string;
    messageGuid: string;
  }

  const [message, setMessage] = useState<any>({
    messageID: 0,
    messageTitle: "",
    messageDetails: "",
    trainerISID: 0,
    userID: 0,
    messageStatus: 0,
    messageDate: "",
    messageURL: "",
    messageGuid: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const [users, setUsers] = useState<any[]>([]);
  const [usersID, setUserID] = useState("");
  const [startDate, setStartDate] = useState(new Date() as any | null);

  /* @ts-ignore*/
  const editorRef = useRef();
  const log = () => {
    if (editorRef.current) {
    }
  };

  const handleInputChange = (event: any, formName: string) => {
    const values: any = { ...message };

    if (message === undefined) {
      values.push({
        messageID: 0,
        messageTitle: "",
        messageDetails: "",
        trainerISID: 10,
        userID: 0,
        messageStatus: 3,
        messageDate: new Date(),
        messageURL: "",
        messageGuid: "",
      });
    }
    if (formName === "messageDetails") {
      const updatedValue = formName;
      values[updatedValue] = event;
    } else {
      const updatedValue = formName;
      values[updatedValue] = event.target.value;
    }

    setMessage(values);
  };

  const [messageStatus, setMessageStatus] = useState("");

  const apiService = new ApiService();

  const onSubmit = (data: any) => {
    PostData(data);
  };

  const fetchMessageByID = async (id: number) => {
    const response = await apiService.getMessageByID(id);
    setMessage(response);
  };

  const PostData = async (e: any) => {
    e.preventDefault();
    const values: any = { ...message };

    if (message.messageStatus === "1") {
      values.userID = usersID;
      values.messageID = 0;
      values.messageGuid = "";
      values.messageDate = new Date();
      values.messageStatus = 1;
    } else {
      values.messageID = message.messageID;
      values.messageGuid = message.messageGuid;
      values.userID = 0;
      values.messageDate = startDate;
      values.messageStatus = 3;
    }

    const response = await apiService.PostData(
      "api/Message/AddMessage",
      values
    );
  };

  const fetchUsers = async () => {
    const response = await apiService.getAllUsers();
    setUsers(response);
  };

  useEffect(() => {
    fetchMessageByID(Number(id));
    fetchUsers();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Edit Message Template</h3>
      <hr></hr>

      <form>
        <div className="row">
          {/* FIRST COL */}
          <div className="col-md-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message Title"
                id="form3Example4"
                defaultValue={message.messageTitle}
                onChange={(e) => handleInputChange(e, "messageTitle")}
              />
            </div>
            <div className="form-group">
              {/* @ts-ignore*/}
              <Editor
                apiKey="h2z52jer61ylbqfag18e0gn0hxvharfdby44xefhs3qyww1g"
                onInit={(evt, editor) =>
                  editorRef.current != undefined ? editor : null
                }
                onEditorChange={(e) => handleInputChange(e, "messageDetails")}
                value={message.messageDetails}
                init={{
                  height: 500,
                  entity_encoding: "named",
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message URL"
                id="form3Example4"
                defaultValue={message.messageURL}
                onChange={(e) => handleInputChange(e, "messageURL")}
              />
            </div>

            <div className="form-group">
              {/* @ts-ignore*/}
              <DatePicker
                className="form-control"
                selected={startDate}
                dateFormat={"dd/MM/yyyy"}
                onChange={(dateSelected) => setStartDate(dateSelected)}
              />
            </div>

            <div className="form-group">
              <div className="custom_select">
                <select
                  className="form-control select-active"
                  defaultValue={message.messageStatus}
                  onChange={(e) => handleInputChange(e, "messageStatus")}
                >
                  <option selected={0 == message.messageStatus} value={0}>
                    Message Status...
                  </option>
                  <option selected={1 == message.messageStatus} value={1}>
                    Published
                  </option>

                  <option selected={2 == message.messageStatus} value={2}>
                    Draft
                  </option>
                  <option selected={3 == message.messageStatus} value={3}>
                    Template
                  </option>
                </select>{" "}
              </div>
            </div>

            {message.messageStatus === "1" ? (
              <div className="form-group">
                <div className="custom_select">
                  <select
                    className="form-control select-active"
                    onChange={(e) => setUserID(e.target.value)}
                  >
                    <option value="0">Select user...</option>
                    {users &&
                      users.map((item: any) => (
                        <option
                          selected={message.userID === item.userID}
                          value={item.userID}
                        >
                          {item.userFirstname} {item.userLastname}
                        </option>
                      ))}
                  </select>{" "}
                </div>
              </div>
            ) : null}

            <hr></hr>
            <button
              type="submit"
              className="btn btn-success hover-up"
              name="login"
              onClick={PostData}
            >
              Save Message
            </button>
            <hr></hr>
          </div>
        </div>
      </form>
    </div>
  );
}
export default EditMessageTemplate;
