import { getBearer } from "../helpers";

export class ApiService {
  vimeoToken = "fe491eb4af0fefb3460566d200e92ee3";

  public async getUser(userEmail: string, userPass: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      "api/User/GetUser/" + userEmail + "/" + userPass,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }
  public async getAllFood(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Food/GetAllFood", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getFoodByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Food/GetFoodByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllCompleteByUser(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Complete/GetAllCompleteByUserID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getAllProgressByUser(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Progress/GetAllProgressByUserID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getExerByID(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Exer/GetExerByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllUsers(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/User/GetAllUsers/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getAllUsersByKeywords(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/User/getAllUsersByKeywords/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getUserByID(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/User/getUserByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllFoodByKeywords(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Food/getAllFoodByKeywords/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllWorkoutsTemplate(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Work/GetAllWorkoutsTemplate/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllWorkoutsNonTemplate(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Work/GetAllWorkoutsNonTemplate/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllTemplateWorkoutByKeywords(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      "api/Work/GetAllTemplateWorkoutsByKeyword/" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getAllWorkouts(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Workout/GetAllWorkouts/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllWorks(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Work/GetAllWorkouts/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllWorkoutsByUserID(id: any): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Workout/GetAllWorkoutByUserID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllWorkoutsByUserIDWithExerPreviews(id: any): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      "api/Workout/GetAllWorkoutByUserIDWithPreviews/" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getAllWorkoutsByUserIDTemplates(id: any): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Workout/GetAllWorkoutByUserID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getTheWorkoutsByUserID(id: any): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Workout/GetTheWorkoutByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllWorkoutByKeywords(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Workout/GetAllWorkoutsByKeyword/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllWorkoutHistoryByUserID(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      "api/WorkoutHistory/GetAllWorkoutHistoryByUserID/" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getAllProgrammesByUserID(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      "api/Programme/GetAllProgrammesByUserID/" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async DeleteMessage(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Message/DeleteMessage/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async DeleteWorkout(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Work/DeleteWork/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async DeleteSavedProgramme(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Programme/DeleteProgramme/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async deleteExerByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Exer/DeleteExer/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async deleteGymByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Gym/DeleteGym/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async deleteProgressByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Progress/DeleteProgress/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getWorkoutByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Work/GetWorkoutByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getCustomByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Custom/GetCustomByWorkoutID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllExerByWorkID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Exer/GetAllExerByWorkID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getAllExerByISID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Exer/GetAllExerByISID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllChartDataHandicap(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Handicap/GetAllHandicapByUserID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getAllChartDataGymHours(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Gym/GetAllGymHoursByUserID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllVideosByCatID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Video/GetAllVideosByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllVimeoFolders(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      "https://api.vimeo.com/users/user177796033/folders",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${this.vimeoToken}`,
        },
      }
    );

    return await response.json();
  }

  public async getAllVimeoVideosByFolderID(id: string): Promise<any> {
    const response = await fetch(
      "https://api.vimeo.com/" + id + "/items?direction=asc&sort=alphabetical",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${this.vimeoToken}`,
        },
      }
    );

    return await response.json();
  }

  public async getAllVimeoVideosByKeyword(id: string): Promise<any> {
    const response = await fetch(
      "https://api.vimeo.com/users/user177796033/videos?query=" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${this.vimeoToken}`,
        },
      }
    );

    return await response.json();
  }

  public async getAllVimeoVideos(): Promise<any> {
    const response = await fetch(
      "https://api.vimeo.com/users/user177796033/videos",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${this.vimeoToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getAllVideosCategories(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/VideoCategory/GetAllVideoCategory", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllVideosByKeywords(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Video/GetAllVideosByKeyword/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getAllMessages(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Message/GetAllMessages", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getAllMessagesByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Message/GetAllMessagesByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getMessageByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Message/getMessageByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getAllResources(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Resource/GetAllResources", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getResourceByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Resource/getResourceByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getDocsByMessageGuid(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Doc/GetAllDocByMessageGuid/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getCart(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Cart/GetAllCartsByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getExchangeRates(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Exchange/GetAllExchangeRates", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async PostWorkoutData(url: any, data: any) {
    let bearerToken = getBearer();
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
  public async PostData(url: any, data: any) {
    let bearerToken = getBearer();
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  public async authUser(userEmail: string): Promise<any> {
    const response = await fetch("api/user/CreateToken", {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        Username: userEmail,
      }),
    });

    return await response.json();
  }

  public async addUser(user: any) {
    const response = await fetch(`/api/user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user }),
    });
    return await response.json();
  }

  public async PostFile(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: data,
    });
    return response;
  }
}
