import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { decode } from "punycode";

let user: {};

export function getToken() {
  return Cookie.get("token");
}
export function getAdminToken() {
  return Cookie.get("admintoken");
}

export function getUser() {
  let token = getToken();
  if (token) {
    user = buildUser(token);
  }
  return user;
}

export function getAdminUser() {
  if (!user) {
    let token = getAdminToken();
    if (token) {
      user = buildAdminUser(token);
    }
  }
  return user;
}

export function setUser(userId: any, token: any) {
  Cookie.set("userID", userId);
  Cookie.set("token", token);

  user = buildUser(token);
}

export function clearUser() {
  Cookie.remove("token");
  user = {};
}

export function clearAdminUser() {
  Cookie.remove("adminuserID");
  Cookie.remove("admintoken");
  user = {};
}

export function isAuthenticated() {
  var session_token = getToken();
  if (!session_token) {
    return false;
  }

  return true;
}

export function buildUser(token: any) {
  let decoded: any = jwt_decode(token);
  return {
    userID: parseInt(decoded.userID),
    displayName: decoded.displayName,
    userFirstname: decoded.userFirstname,
    userLastname: decoded.userLastname,
    userGuid: decoded.userGuid,
    userEmail: decoded.userEmail,
    trainerID: parseInt(decoded.trainerID),
    ISID: parseInt(decoded.ISID),
    dateCreated: decoded.dateCreated,
  };
}

export function buildAdminUser(token: any) {
  let decoded: any = jwt_decode(token);
  return {
    userID: parseInt(decoded.userID),
    displayName: decoded.displayName,
    userFirstname: decoded.userFirstname,
    userSurname: decoded.userSurname,
    userGuid: decoded.userGuid,
    userEmail: decoded.userEmail,
    ISID: parseInt(decoded.ISID),
  };
}
