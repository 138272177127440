import React, { ReactElement, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { ApiService } from "../../services/api.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";

interface Message {
  messageID: number;
  messageTitle: string;
  messageDetails: string;
  trainerISID: number;
  userID: number;
  messageStatus: number;
  messageDate: Date;
  messageURL: string;
  messageGuid: string;
}

function CreateMessageTemplate(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const [users, setUsers] = useState<any[]>([]);
  const [usersID, setUserID] = useState("");

  const [message, setMessage] = useState<any>({
    messageID: 0,
    messageTitle: "",
    messageDetails: "",
    trainerISID: 0,
    userID: 0,
    messageStatus: 0,
    messageDate: null,
    messageURL: "",
    messageGuid: "",
  });

  const apiService = new ApiService();

  const onSubmit = (data: any) => {
    PostData(data);
  };

  /* @ts-ignore*/
  const editorRef = useRef();
  const log = () => {
    if (editorRef.current) {
    }
  };

  const handleInputChange = (event: any, formName: string) => {
    const values: any = { ...message };

    if (message === undefined) {
      values.push({
        messageID: 0,
        messageTitle: "",
        messageDetails: "",
        trainerISID: 10,
        userID: 0,
        messageStatus: 3,
        messageDate: new Date(),
        messageURL: "",
        messageGuid: "",
      });
    }
    if (formName === "messageDetails") {
      const updatedValue = formName;
      values[updatedValue] = event;
    } else {
      const updatedValue = formName;
      values[updatedValue] = event.target.value;
    }

    setMessage(values);
  };

  const PostData = async (e: any) => {
    e.preventDefault();
    const values: any = { ...message };
    values.messageDate = new Date();
    values.trainerISID = 10;
    values.messageStatus = 3;

    const response = await apiService.PostData(
      "api/Message/AddMessage",
      values
    );
  };

  const fetchUsers = async () => {
    const response = await apiService.getAllUsers();
    setUsers(response);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Create Message Template</h3>
      <hr></hr>

      <form>
        <div className="row">
          {/* FIRST COL */}
          <div className="col-md-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message Title"
                id="form3Example4"
                onChange={(e) => handleInputChange(e, "messageTitle")}
              />
            </div>

            {/* @ts-ignore*/}
            <Editor
              apiKey="h2z52jer61ylbqfag18e0gn0hxvharfdby44xefhs3qyww1g"
              onInit={(evt, editor) =>
                editorRef.current != undefined ? editor : null
              }
              onEditorChange={(e) => handleInputChange(e, "messageDetails")}
              value={message.messageDetails}
              init={{
                height: 500,
                entity_encoding: "named",
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message URL"
                id="form3Example4"
                onChange={(e) => handleInputChange(e, "messageURL")}
              />
            </div>

            <hr></hr>
            <button
              type="submit"
              className="btn btn-success hover-up"
              name="login"
              onClick={PostData}
            >
              Save Message
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
export default CreateMessageTemplate;
