import React, { ReactElement, useState, useContext } from "react";
import Cookie from "js-cookie";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router";
import { setUser, getUser } from "../../services/auth.service";
import { Link } from "react-router-dom";

function Login(): ReactElement {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  let [hidden, setHidden] = useState("");

  const navigate = useNavigate();

  const { setUserData } = useContext(AuthContext);

  const onSubmit = (e: any) => {
    e.preventDefault();

    fetch("api/user/CreateToken", {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        Username: userEmail,
        Password: userPassword,
      }),
    })
      .then((Response) => Response.json())
      .then((result) => {
        Cookie.set("token", result.token);

        setUser(result.userID, result.token);

        // const cookieVal = Cookie.get("cart");
        // if (cookieVal !== undefined) {
        //
        //
        //   setCartGuid(cookieVal);
        // } else {
        //

        const user = getUser();

        setUserData(user);

        if (result.userGuid !== "") {
          navigate("/");
        }
      })
      .catch((error) => {
        setHidden("Invalid credentials");
      });
  };

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Member Login</h3>
      <hr></hr>
      <div className="row">
        <div className="col-lg-6 m-auto d-flex align-items-center justify-content-center">
          <div className="clearfix"></div>
          <div className="row">
            <form
              name="loginForm"
              style={{ minWidth: "360px" }}
              onSubmit={onSubmit}
            >
              {hidden !== "" ? <h4 className="pb-20 error">{hidden}</h4> : null}
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  onChange={(e) => setUserEmail(e.target.value)}
                ></input>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={(e) => setUserPassword(e.target.value)}
                ></input>
              </div>

              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </form>

            <br></br>
            <br></br>
            <p>
              <Link
                style={{ marginTop: "40px", float: "right" }}
                className="nav-link link-light px-2"
                to="../Forgot-Password"
              >
                Forgot Password
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
