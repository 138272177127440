import moment from "moment";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { FormatDate } from "../../helpers";
import { ApiService } from "../../services/api.service";
import HandicapTracker from "../Charty/handicap";
import Accordion from "react-bootstrap/Accordion";
import Vimeo from "@u-wave/react-vimeo";
import Loader from "../Loader";
import Messages from "../Messages";

interface User {
  userID: number;
  userGUID: string;
  userEmail: string;
  userFirstname: string;
  userLastname: string;
  dateCreated: string;
  isid: number;
  trainerID: number;
}
interface UserLogged {
  userID: number;
  userGUID: string;
  userEmail: string;
  userFirstname: string;
  userLastname: string;
  dateCreated: string;
  isid: number;
  trainerID: number;
}

function ClientDetails(): ReactElement {
  const today = new Date();

  const [clientData, setClientData] = useState<User>({
    userID: 0,
    userGUID: "",
    userEmail: "",
    userFirstname: "",
    userLastname: "",
    dateCreated: "",
    isid: 0,
    trainerID: 0,
  });
  const { userData, setUserData } = useContext(AuthContext);
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const [workouts, setWorkouts] = useState<any[]>([]);
  const [programmes, setProgrammes] = useState<any[]>([]);
  const [messages, setMessages] = useState<any[]>([]);

  const [selectedProgramme, setSelectedProgramme] = useState("");

  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [fileExists, setFileExists] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");

  const [clientISID, setClientISID] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const apiService = new ApiService();

  const fetchUser = async (e: string) => {
    setLoading(true);
    const response = await apiService.getUserByID(e);
    setClientData(response);
    setLoading(false);

    fetchProgrammes();
    fetchMessages(response.userID);
    fetchWorkouts(response.isid);
  };

  const fetchMessages = async (e: number) => {
    setLoading(true);
    const response = await apiService.getAllMessagesByID(e);
    setMessages(response);
    setLoading(false);
  };

  const saveProgramme = async () => {
    const data = {
      workoutID: 0,
      workID: parseInt(selectedProgramme),
      dateCreated: new Date(),
      ISID: clientData.isid,
      week: 99,
    };

    const response = await apiService.PostData(
      "../api/Workout/AddWorkout",
      data
    );
    fetchUser(clientData.userGUID);
  };

  const fetchProgrammes = async () => {
    setLoading(true);
    const response = await apiService.getAllWorks();
    setProgrammes(response);
    setLoading(false);
  };

  const fetchWorkouts = async (e: any) => {
    setLoading(true);
    var response;

    if (userData.trainerID === 1) {
      // response = await apiService.getAllWorkoutsByUserIDWithExerPreviews(e);
      response = await apiService.getAllWorkoutsByUserID(e);
    } else {
      response = await apiService.getAllWorkoutsByUserID(e);
    }
    setWorkouts(response);
    setLoading(false);
  };

  const onFileChange = (e: any) => {
    // Update the state
    setSelectedFile(e.target.files[0]);
  };

  const onFileUpload = (e: any) => {
    e.preventDefault();
    setUploading(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("myFile", selectedFile, userData.ISID.toString());

    PostData(formData);
  };

  const PostData = async (data: any) => {
    setUploading(true);
    const response = await apiService.PostFile("api/User/UploadFile", data);
    if (response.ok) {
      setUploadComplete(true);
    } else {
    }
    setUploading(false);
  };

  const handleClick = (e: any, workoutID: any) => {
    let path = `/WorkoutDetails?id=${e}&workoutID=${workoutID}`;
    navigate(path);
  };

  useEffect(() => {
    fetchUser(String(id));
    fetchProgrammes();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Client Details</h3>
      <hr></hr>
      <div className="row">
        <div className="col-md-3">
          <img
            src={`/img/avatars/${clientData.isid}/${clientData.isid}.jpg`}
            width="100%"
            className="img img-thumbnail rounded-circle"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/img/avatars/none.jpg";
            }}
          ></img>
          <hr></hr>
          <h2>
            {clientData.userFirstname} {clientData.userLastname}
          </h2>
          <p>Member since {FormatDate(new Date(clientData.dateCreated))}</p>
          <input className="inputfile" type="file" onChange={onFileChange} />
          <button className="btn btn-success" onClick={onFileUpload}>
            {uploading ? (
              <span>Uploading</span>
            ) : (
              <span>Upload a Profile Photo</span>
            )}
          </button>
        </div>

        <div className="col-md-9">
          {/* <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Programme Name</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {workouts &&
                workouts.map((item, index) => (
                  <tr onClick={() => handleClick(item.workID, item.workoutID)}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.work.workTitle} </td>
                    <td> {FormatDate(item.dateCreated)} </td>
                  </tr>
                ))}
            </tbody>
          </table> */}
          {userData.trainerID == 1 ? (
            <div>
              <h3>Assign Workouts</h3>

              <div className="form-group">
                <div className="custom_select">
                  <select
                    className="form-control select-active"
                    onChange={(e) => setSelectedProgramme(e.target.value)}
                  >
                    <option value="0">Select programme to assign...</option>
                    {programmes &&
                      programmes.map((item: any) => (
                        <option value={item.workID}>
                          {item.workTitle}{" "}
                          {item.templateID === 1 ? " (TEMPLATE)" : null}
                        </option>
                      ))}
                  </select>
                </div>

                <button
                  type="submit"
                  className="btn btn-success hover-up"
                  name="login"
                  onClick={(e) => saveProgramme()}
                >
                  Assign programme
                </button>
                <hr></hr>
              </div>
            </div>
          ) : null}
          <h3>Your Programmes</h3>
          <hr></hr>

          {/* {userData.trainerID === 1
            ? workouts &&
              workouts.map((item, index) => (
                <React.Fragment>
                  <Accordion>
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header>{item.work.workTitle}</Accordion.Header>
                      <Accordion.Body>
                        {item.exer &&
                          item.exer.map((item2: any, index: number) => (
                            <div className="row">
                              <h1></h1>

                              <div className="col-md-12 exerDetails">
                                <h3>
                                  <span className="c-red">
                                    {item2.exerCode}
                                  </span>
                                  . {item2.exerName}
                                </h3>
                                <p>
                                  <b>Sets:</b> {item2.exerSet} <b>Reps:</b>{" "}
                                  {item2.exerRep} <b>Rest:</b> {item2.exerRest}
                                </p>
                              </div>
                            </div>
                          ))}
                        <hr></hr>
                        <button
                          className="btn btn-success"
                          onClick={() =>
                            handleClick(item.workID, item.workoutID)
                          }
                        >
                          View This Workout
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </React.Fragment>
              ))
            : null} */}

          {/* {userData.trainerID === 0 ? ( */}
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Programme Name</th>
                <th scope="col">Date</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {workouts &&
                workouts.map((item, index) => (
                  <tr onClick={() => handleClick(item.workID, item.workoutID)}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.work.workTitle} </td>
                    <td> {FormatDate(item.dateCreated)} </td>
                    <td>View</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* ) : null} */}
          <hr></hr>
          <h3>Client Messages</h3>
          <hr></hr>

          {messages &&
            messages.map((item, index) => (
              <React.Fragment>
                <Accordion>
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{item.messageTitle}</Accordion.Header>
                    <Accordion.Body>
                      <div className="col-md-12 exerDetails">
                        <p style={{ fontWeight: "bolder" }}>
                          <img
                            src={`/img/avatars/${item.trainerISID}/${item.trainerISID}.jpg`}
                            alt="mdo"
                            width="32"
                            height="32"
                            className="rounded-circle"
                          ></img>{" "}
                          {item.user.userFirstname} |{" "}
                          {FormatDate(new Date(item.messageDate))}
                        </p>
                        <hr></hr>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.messageDetails,
                          }}
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
export default ClientDetails;
