import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

function MainHeader(): ReactElement {
  const { userData, setUserData } = useContext(AuthContext);

  return (
    <div
      className="px-3 py-2 "
      style={{
        backgroundColor: "#000",
      }}
    >
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a
            href="/"
            className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
          >
            <img src="./img/logo.png"></img>
          </a>

          <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
            {/* <li>
              <a href="#" className="nav-link text-secondary">
                <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                  <use xlinkHref="#bootstrap2" />
                </svg>
                Home
              </a>
            </li> */}
            {userData.userGuid ? (
              <li>
                <Link className="nav-link text-white" to="/">
                  <svg
                    className="bi d-block mx-auto mb-1"
                    width="24"
                    height="24"
                  >
                    <use xlinkHref="#speedometer2" />
                  </svg>
                  Dashboard
                </Link>
              </li>
            ) : null}
            {userData.userGuid ? (
              <li>
                <Link className="nav-link text-white" to="/Workouts">
                  <svg
                    className="bi d-block mx-auto mb-1"
                    width="24"
                    height="24"
                  >
                    <use xlinkHref="#table" />
                  </svg>
                  Workouts
                </Link>
              </li>
            ) : null}
            {userData.userGuid ? (
              <li>
                <Link className="nav-link text-white" to="/Message-Centre">
                  <svg
                    className="bi d-block mx-auto mb-1"
                    width="24"
                    height="24"
                  >
                    <use xlinkHref="#grid" />
                  </svg>
                  Messages
                </Link>
              </li>
            ) : null}
            {userData.userGuid ? (
              <li>
                <Link className="nav-link text-white" to="/Recipes">
                  <svg
                    className="bi d-block mx-auto mb-1"
                    width="24"
                    height="24"
                  >
                    <use xlinkHref="#people-circle" />
                  </svg>
                  Recipes
                </Link>
              </li>
            ) : null}

            {!userData.userGuid ? (
              <li>
                <Link className="nav-link text-white" to="/Login">
                  <svg
                    className="bi d-block mx-auto mb-1"
                    width="24"
                    height="24"
                  >
                    <use xlinkHref="#test" />
                  </svg>
                  Login
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default MainHeader;
