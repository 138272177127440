import React, { useState, ReactElement, useContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import { AuthContext, AuthProvider } from "./context/authContext";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./index.css";
import { useLocation } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";

import Workouts from "./components/Workouts";
import Recipes from "./components/Recipes";
import Clients from "./components/Clients";
import WorkoutDetails from "./components/WorkoutDetails";
import Exercises from "./components/Exercises";
import Messages from "./components/Messages";
import Resources from "./components/Resources";
import ClientDetails from "./components/Clients/Client-Details";
import EditWorkout from "./components/WorkoutsAdmin/Edit";
import MainHeader from "./components/shared/mainHeader";
import SubHeader from "./components/shared/subHeader";
import Home from "./components/Home";
import Cookies from "js-cookie";
import { buildUser } from "./services/auth.service";
import TopHeader from "./components/shared/topheader";
import RecipeDetails from "./components/Recipes/recipe-details";
import WorkoutsAdmin from "./components/WorkoutsAdmin";
import MessagesAdmin from "./components/MessagesAdmin";
import CreateMessage from "./components/CreateMessage";
import ForgotPassword from "./components/Forgot-Password";
import TemplatesAdmin from "./components/Templates-Admin";
import SavedWorkouts from "./components/SavedWorkouts";
import GymCalendar from "./components/GymCalendar";
import HandicapCalendar from "./components/HandicapCalendar";
import EditMessage from "./components/EditMessage";
import MessagesTemplates from "./components/Messages-Templates";
import EditMessageTemplate from "./components/EditMessageTemplate";
import CreateMessageTemplate from "./components/CreateMessageTemplate";
import ResourcesAdmin from "./components/ResourcesAdmin";
import EditResource from "./components/EditResource";
import RecipeAdmin from "./components/RecipeAdmin";
import EditRecipe from "./components/EditRecipe";
import CreateResource from "./components/CreateResource";
import PasswordSent from "./components/PasswordSent";
import SavedDetails from "./components/SavedWorkouts/savedDetails";

function App(): ReactElement {
  // Sentry.init({
  //   dsn: "https://b57912cf003e436bb7cd862e70599b94@o4505274150879232.ingest.sentry.io/4505550633959424",

  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

  //   // Session Replay
  // });

  const { userData } = useContext(AuthContext);

  const checkLogIn = () => {
    if (!userData.userGuid) {
      <Navigate to="./login" replace />;
    }
  };

  useEffect(() => {
    checkLogIn();
  }, []);

  return (
    <div>
      <div style={{ backgroundColor: "#efefef" }}>
        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol id="bootstrap2" viewBox="0 0 16 16">
            <title>Bootstrap</title>
            <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
          </symbol>

          <symbol id="bootstrap" viewBox="0 0 118 94">
            <title>Bootstrap</title>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"
            ></path>
          </symbol>
          <symbol id="home" viewBox="0 0 16 16">
            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
          </symbol>
          <symbol id="speedometer2" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
            <path
              fill-rule="evenodd"
              d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
            />
          </symbol>
          <symbol id="table" viewBox="0 0 16 16">
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6 6.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V6.884z" />
          </symbol>
          <symbol id="people-circle" viewBox="0 0 1000 1000">
            <path d="M972.5,119.1c-9.7-9.7-22.6-15.9-36.2-17.2c-25.3-2.5-94.6,38-133.6,62.4c-55.9,34.9-140.1,94.1-223.7,177.7l-183,182.9c-23.3,23.3-23.3,61.2,0,84.5l0.9,0.8L145.8,861.3c-23.3,23.3-23.3,61.2,0,84.5c23.3,23.3,61.2,23.3,84.5,0l742.2-742.2c16.2-16.2,21.7-40.7,14-62.3C983.7,133,978.8,125.3,972.5,119.1L972.5,119.1L972.5,119.1L972.5,119.1z" />
            <path d="M644.4,592.7l-84.5,84.5l262.4,262.4c23.3,23.3,61.2,23.3,84.4,0c23.3-23.3,23.3-61.2,0-84.5L644.4,592.7L644.4,592.7z" />
            <path d="M305.7,421.2l65.6,65.6l83.3-83.3l-62.4-62.4c11.5-39.1,1.9-83.2-28.9-114c-0.3-0.3-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.7-0.8-1L178.8,42.6c-7.7-7.7-20.1-7.7-27.8,0c-7.7,7.7-7.7,20.1,0,27.8L320.7,240c0,0,0.1,0.1,0.2,0.1c4.8,4.8,4.8,12.5,0,17.3c-4.8,4.8-12.5,4.8-17.3,0c0,0-0.1-0.1-0.1-0.1l0,0L133.7,87.6c-7.7-7.7-20.1-7.7-27.8,0c-7.7,7.7-7.7,20.1,0,27.8l169.7,169.8c0,0,0.1,0,0.1,0.1c4.8,4.8,4.8,12.5,0,17.3c-4.8,4.8-12.5,4.8-17.3,0c0,0,0-0.1-0.1-0.1l0,0L88.6,132.7c-7.7-7.7-20.1-7.7-27.8,0c-7.7,7.7-7.7,20.1,0,27.8l169.7,169.7c0,0,0.1,0.1,0.1,0.1c4.8,4.8,4.8,12.5,0,17.3c-4.8,4.8-12.5,4.8-17.3,0c0,0,0-0.1-0.1-0.1l0,0.1L43.5,177.8c-7.6-7.7-20.1-7.7-27.8,0c-7.7,7.7-7.7,20.1,0,27.8l182.8,182.8c0.3,0.3,0.7,0.5,1,0.8c0.3,0.3,0.5,0.7,0.8,1C228.9,418.7,268.9,429,305.7,421.2L305.7,421.2L305.7,421.2z" />
            <path d="M895.7,844.7L655.9,604.8L572.6,688l239.9,239.9c23,23,60.3,23,83.3,0C918.7,905,918.7,867.6,895.7,844.7L895.7,844.7L895.7,844.7z" />
          </symbol>
          <symbol id="grid" viewBox="0 0 16 16">
            <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z" />
          </symbol>
          <symbol id="bootstrap" viewBox="0 0 118 94">
            <title>Bootstrap</title>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"
            ></path>
          </symbol>
          <symbol id="facebook" viewBox="0 0 16 16">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
          </symbol>
          <symbol id="instagram" viewBox="0 0 16 16">
            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
          </symbol>
          <symbol id="twitter" viewBox="0 0 16 16">
            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
          </symbol>

          <symbol id="test" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
            />
            <path
              fill-rule="evenodd"
              d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
            />
          </symbol>
        </svg>
      </div>

      {!userData.userGuid ? (
        <>
          <MainHeader />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/Password-Sent" element={<PasswordSent />} />

            <Route
              path="/login"
              element={
                !userData.userGuid ? <Navigate to="/login" /> : <Login />
              }
            ></Route>
          </Routes>
        </>
      ) : (
        <div className="d-flex flex-column min-vh-100">
          <MainHeader />
          <TopHeader />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Workouts" element={<Workouts />} />
            <Route path="/Workouts-Admin" element={<WorkoutsAdmin />} />

            <Route path="/Recipes" element={<Recipes />} />
            <Route path="/Recipe-Details" element={<RecipeDetails />} />

            <Route path="/Clients" element={<Clients />} />
            <Route path="/WorkoutDetails" element={<WorkoutDetails />} />
            <Route path="/Exercises" element={<Exercises />} />
            <Route path="/Message-Centre" element={<Messages />} />
            <Route path="/Messages-Admin" element={<MessagesAdmin />} />
            <Route path="/Messages-Templates" element={<MessagesTemplates />} />
            <Route path="/Saved-Details" element={<SavedDetails />} />

            <Route path="/Resources" element={<Resources />} />
            <Route path="/Client-Details" element={<ClientDetails />} />
            <Route path="/Edit-Workout" element={<EditWorkout />} />
            <Route path="/Edit-Resource" element={<EditResource />} />
            <Route path="/Create-Resource" element={<CreateResource />} />

            <Route path="/Edit-Recipe" element={<EditRecipe />} />

            <Route path="/Create-Message" element={<CreateMessage />} />
            <Route
              path="/Create-Message-Template"
              element={<CreateMessageTemplate />}
            />

            <Route path="/Edit-Message" element={<EditMessage />} />
            <Route
              path="/Edit-Message-Template"
              element={<EditMessageTemplate />}
            />
            <Route path="/Resources-Admin" element={<ResourcesAdmin />} />
            <Route path="/Recipes-Admin" element={<RecipeAdmin />} />

            <Route path="/Forgot-Password" element={<ForgotPassword />} />
            <Route path="/Password-Sent" element={<PasswordSent />} />
            <Route path="/Templates-Admin" element={<TemplatesAdmin />} />
            <Route path="/Gym-Calendar" element={<GymCalendar />} />
            <Route path="/Handicap-Calendar" element={<HandicapCalendar />} />

            <Route path="/Saved-Workouts" element={<SavedWorkouts />} />
          </Routes>
        </div>
      )}
    </div>
  );
}

// function MainLayout(): ReactElement {
//   return (
//     <div>
//       <MainHeader />
//       <SubHeader />
//       <Routes></Routes>
//     </div>
//   );
// }

// function AdminLayout(): ReactElement {
//   return (
//     <div>
//       <MainHeader />

//       <Routes>
//         <Route path="/Login" element={<Login />} />
//       </Routes>
//     </div>
//   );
// }

export default App;
