import React, { ReactElement, useState, useEffect, useContext } from "react";
import ChartistGraph from "react-chartist";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/authContext";
import { FormatDate } from "../../helpers";
import { ApiService } from "../../services/api.service";
import { Link } from "react-router-dom";

function GymHours(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const [chartData, setChartData] = useState<any[]>([]);
  const [labels, setLabels] = useState<any[]>([]);
  const [series, setSeries] = useState<any[]>([]);
  const { userData, setUserData } = useContext(AuthContext);

  const apiService = new ApiService();

  const fetchChartData = async () => {
    const response = await apiService.getAllChartDataGymHours(userData.ISID);
    setChartData(response);
  };
  useEffect(() => {
    fetchChartData();
  }, []);

  var data = {
    labels: chartData.map((d: any) => FormatDate(d.dateCreated)),
    series: [chartData.map((d: any) => d.gymValue)],
  };

  var options = {
    high: Math.max.apply(0, chartData),
    low: 0,
    axisX: {
      labelInterpolationFnc: function (value: number, index: number) {
        return index % 1 === 0 ? value : null;
      },
    },
    seriesBarDistance: 10,
    fullWidth: true,
    showArea: true,
    height: "300px",
  };

  const onSubmit = async (data: any) => {
    data.ISID = userData.ISID;
    data.dateCreated = new Date(data.dateCreated);
    const response = await apiService.PostData("api/Gym/AddGym", data);
    reset();
    fetchChartData();
  };

  var type = "Line";

  return (
    <div>
      <h3>Gym Hours Tracker</h3> <hr></hr>
      <div className="ct-chart" id="chartHours">
        {/*@ts-ignore*/}
        <ChartistGraph data={data} options={options} type={type} />{" "}
        <div
          style={{
            textAlign: "right",
            marginTop: "15px",
            paddingRight: "15px",
          }}
        >
          <Link className="btn btn-success" to="/Gym-Calendar">
            Update
          </Link>
        </div>
        <hr></hr>
      </div>
    </div>
  );
}
export default GymHours;
