import moment from "moment";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { ApiService } from "../../services/api.service";
import GymHours from "../Charty/gymHours";
import HandicapTracker from "../Charty/handicap";
import Checklist from "../Checklist";
import Loader from "../Loader";
import Progress from "../Progress";
function Home(): ReactElement {
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [workouts, setWorkouts] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const apiService = new ApiService();

  const fetchWorkouts = async (e: any) => {
    setLoading(true);
    var response;
    response = await apiService.getAllWorkoutsByUserID(e);
    setWorkouts(response);

    setLoading(false);
    window.scrollTo(0, 0);
  };

  let navigate = useNavigate();

  const handleClick = (e: any, workoutID: any) => {
    let path = `/WorkoutDetails?id=${e}&workoutID=${workoutID}`;
    navigate(path);
  };
  useEffect(() => {
    fetchWorkouts(userData.ISID);
  }, []);

  return (
    <div>
      <div>
        <main>
          <div className="container">
            <div className="p-5 mb-4 bg-light rounded-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="container-fluid py-5">
                    <h5
                      className="display-6 fw-bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      Hi {userData.displayName}
                    </h5>
                    <p className="col-md-12 fs-4">
                      Pro-Fit Golf has been built on a lifelong passion for golf
                      and fitness. Our mission is to help golfers of every
                      ability level improve their games by working on their
                      physical fitness. We know each and every golfer swings the
                      club differently and that’s because our bodies are all
                      unique.
                    </p>
                    {/* <img
                        className="img-thumbnail"
                        src={` https://pro-fitgolfconditioning.com/images/hp_image.png`}
                        style={{
                          maxWidth: "100%",
                        }}
                      ></img> */}
                    <Link
                      to="./Workouts"
                      className="btn btn-success btn-lg"
                      type="button"
                    >
                      Let's get started{" "}
                    </Link>
                  </div>
                </div>

                <div className="col-md-6">
                  <h4>Your Latest Programmes</h4>
                  <hr></hr>
                  {loading ? (
                    <Loader></Loader>
                  ) : (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Programme Name</th>
                          <th scope="col">Date</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {workouts &&
                          workouts.slice(0, 8).map((item, index) => (
                            <tr
                              onClick={() =>
                                handleClick(item.workID, item.workoutID)
                              }
                            >
                              <th scope="row">{index + 1}</th>
                              <td>{item.work.workTitle} </td>
                              <td>
                                {" "}
                                {moment(item.dateCreated).format(
                                  "DD MMMM yyyy"
                                )}{" "}
                              </td>
                              <td>View</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            <div className="p-3 mb-4 bg-light rounded-3">
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-6" style={{ height: "500px;" }}>
                    <HandicapTracker></HandicapTracker>
                  </div>
                  <div className="col-md-6" style={{ height: "500px;" }}>
                    <GymHours></GymHours>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6" style={{ height: "500px;" }}>
                    <Checklist></Checklist>
                  </div>
                  <div className="col-md-6" style={{ height: "500px;" }}></div>
                </div>
              </div>
            </div>

            {/* <div className="row align-items-md-stretch">
              <div className="col-md-6">
                <div className="h-100 p-5 text-bg-dark rounded-3">
                  <h2>Change the background</h2>
                  <p>
                    Swap the background-color utility and add a `.text-*` color
                    utility to mix up the jumbotron look. Then, mix and match
                    with additional component themes and more.
                  </p>
                  <button className="btn btn-outline-light" type="button">
                    Example button
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="h-100 p-5 bg-light border rounded-3">
                  <h2>Add borders</h2>
                  <p>
                    Or, keep it light and add a border for some added definition
                    to the boundaries of your content. Be sure to look under the
                    hood at the source HTML here as we've adjusted the alignment
                    and sizing of both column's content for equal-height.
                  </p>
                  <button className="btn btn-outline-secondary" type="button">
                    Example button
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          {/* <section className="py-5 text-left container">
            <div className="row py-lg-5">
              <div className="col-lg-12 col-md-8 mx-auto">
                <h1 className="fw-light">Album example</h1>
                <p className="lead text-muted">
                  Something short and leading about the collection below—its
                  contents, the creator, etc. Make it short and sweet, but not
                  too short so folks don’t simply skip over it entirely.
                </p>
                <p>
                  <a href="#" className="btn btn-primary my-2">
                    Main call to action
                  </a>
                  <a href="#" className="btn btn-secondary my-2">
                    Secondary action
                  </a>
                </p>
              </div>
            </div>

            <div className="container">
              <div className="row g-5">
                <div className="col-md-6">
                  <h2>Starter projects</h2>
                  <p>
                    Ready to beyond the starter template? Check out these open
                    source projects that you can quickly duplicate to a new
                    GitHub repository.
                  </p>
                  <ul className="icon-list ps-0">
                    <li className="d-flex align-items-start mb-1">
                      <a
                        href="https://github.com/twbs/bootstrap-npm-starter"
                        rel="noopener"
                        target="_blank"
                      >
                        Bootstrap npm starter
                      </a>
                    </li>
                    <li className="text-muted d-flex align-items-start mb-1">
                      Bootstrap Parcel starter (coming soon!)
                    </li>
                  </ul>
                </div>

                <div className="col-md-6">
                  <h2>Guides</h2>
                  <p>
                    Read more detailed instructions and documentation on using
                    or contributing to Bootstrap.
                  </p>
                  <ul className="icon-list ps-0">
                    <li className="d-flex align-items-start mb-1">
                      <a href="/docs/5.2/getting-started/introduction/">
                        Bootstrap quick start guide
                      </a>
                    </li>
                    <li className="d-flex align-items-start mb-1">
                      <a href="/docs/5.2/getting-started/webpack/">
                        Bootstrap Webpack guide
                      </a>
                    </li>
                    <li className="d-flex align-items-start mb-1">
                      <a href="/docs/5.2/getting-started/parcel/">
                        Bootstrap Parcel guide
                      </a>
                    </li>
                    <li className="d-flex align-items-start mb-1">
                      <a href="/docs/5.2/getting-started/vite/">
                        Bootstrap Vite guide
                      </a>
                    </li>
                    <li className="d-flex align-items-start mb-1">
                      <a href="/docs/5.2/getting-started/contribute/">
                        Contributing to Bootstrap
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section> */}

          {/* <main className="container">
            <div className="d-flex align-items-center p-3 my-3 text-white bg-purple rounded shadow-sm">
              <img
                className="me-3"
                src="https://getbootstrap.com/docs/5.2/assets/brand/bootstrap-logo-white.svg"
                alt=""
                width="48"
                height="38"
              ></img>
              <div className="lh-1">
                <h1 className="h6 mb-0 text-white lh-1">Bootstrap</h1>
                <small>Since 2011</small>
              </div>
            </div>

            <div className="my-3 p-3 bg-body rounded shadow-sm">
              <h6 className="border-bottom pb-2 mb-0">Recent updates</h6>
              <div className="d-flex text-muted pt-3">
                <svg
                  className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 32x32"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#007bff" />
                  <text x="50%" y="50%" fill="#007bff" dy=".3em">
                    32x32
                  </text>
                </svg>

                <p className="pb-3 mb-0 small lh-sm border-bottom">
                  <strong className="d-block text-gray-dark">@username</strong>
                  Some representative placeholder content, with some information
                  about this user. Imagine this being some sort of status
                  update, perhaps?
                </p>
              </div>
              <div className="d-flex text-muted pt-3">
                <svg
                  className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 32x32"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#e83e8c" />
                  <text x="50%" y="50%" fill="#e83e8c" dy=".3em">
                    32x32
                  </text>
                </svg>

                <p className="pb-3 mb-0 small lh-sm border-bottom">
                  <strong className="d-block text-gray-dark">@username</strong>
                  Some more representative placeholder content, related to this
                  other user. Another status update, perhaps.
                </p>
              </div>
              <div className="d-flex text-muted pt-3">
                <svg
                  className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 32x32"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#6f42c1" />
                  <text x="50%" y="50%" fill="#6f42c1" dy=".3em">
                    32x32
                  </text>
                </svg>

                <p className="pb-3 mb-0 small lh-sm border-bottom">
                  <strong className="d-block text-gray-dark">@username</strong>
                  This user also gets some representative placeholder content.
                  Maybe they did something interesting, and you really want to
                  highlight this in the recent updates.
                </p>
              </div>
              <small className="d-block text-end mt-3">
                <a href="#">All updates</a>
              </small>
            </div>

            <div className="my-3 p-3 bg-body rounded shadow-sm">
              <h6 className="border-bottom pb-2 mb-0">Suggestions</h6>
              <div className="d-flex text-muted pt-3">
                <svg
                  className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 32x32"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#007bff" />
                  <text x="50%" y="50%" fill="#007bff" dy=".3em">
                    32x32
                  </text>
                </svg>

                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                  <div className="d-flex justify-content-between">
                    <strong className="text-gray-dark">Full Name</strong>
                    <a href="#">Follow</a>
                  </div>
                  <span className="d-block">@username</span>
                </div>
              </div>
              <div className="d-flex text-muted pt-3">
                <svg
                  className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 32x32"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#007bff" />
                  <text x="50%" y="50%" fill="#007bff" dy=".3em">
                    32x32
                  </text>
                </svg>

                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                  <div className="d-flex justify-content-between">
                    <strong className="text-gray-dark">Full Name</strong>
                    <a href="#">Follow</a>
                  </div>
                  <span className="d-block">@username</span>
                </div>
              </div>
              <div className="d-flex text-muted pt-3">
                <svg
                  className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 32x32"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#007bff" />
                  <text x="50%" y="50%" fill="#007bff" dy=".3em">
                    32x32
                  </text>
                </svg>

                <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                  <div className="d-flex justify-content-between">
                    <strong className="text-gray-dark">Full Name</strong>
                    <a href="#">Follow</a>
                  </div>
                  <span className="d-block">@username</span>
                </div>
              </div>
              <small className="d-block text-end mt-3">
                <a href="#">All suggestions</a>
              </small>
            </div>
          </main> */}

          {/* <div className="album py-5 bg-light">
            <div className="container">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card shadow-sm">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#55595c" />
                      <text x="50%" y="50%" fill="#eceeef" dy=".3em">
                        Thumbnail
                      </text>
                    </svg>

                    <div className="card-body">
                      <p className="card-text">
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                          >
                            Edit
                          </button>
                        </div>
                        <small className="text-muted">9 mins</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </main>
        <div className="container">
          <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <div className="col-md-4 d-flex align-items-center">
              <a
                href="/"
                className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
              >
                <svg className="bi" width="30" height="24">
                  <use xlinkHref="#bootstrap" />
                </svg>
              </a>
              <span className="mb-3 mb-md-0 text-muted">
                &copy; 2023 Pro-Fit Golf Conditioning
              </span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                <a className="text-muted" href="#">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#twitter" />
                  </svg>
                </a>
              </li>
              <li className="ms-3">
                <a className="text-muted" href="#">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#instagram" />
                  </svg>
                </a>
              </li>
              <li className="ms-3">
                <a className="text-muted" href="#">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#facebook" />
                  </svg>
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Home;
