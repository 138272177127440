import React, { ReactElement, useState, useEffect } from "react";
import { ApiService } from "../../services/api.service";
import { Container, Row, Col, Form } from "react-bootstrap";
import Loader from "../Loader";
import { Link } from "react-router-dom";

function Recipes(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [recipes, setRecipes] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const apiService = new ApiService();

  const fetchRecipes = async () => {
    setLoading(true);
    const response = await apiService.getAllFood();
    setRecipes(response);
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const handleKeyword = (e: any) => {
    setSearchKeyword(e);
    const apiService = new ApiService();
    const fetchRecipesByKeyword = async () => {
      const response = await apiService.getAllFoodByKeywords(e);
      setRecipes(response);
      setLoading(false);
    };
    fetchRecipesByKeyword();
  };

  useEffect(() => {
    fetchRecipes();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Recipes</h3>
      <hr></hr>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Recipe Search</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter keyword"
            onChange={(e) => handleKeyword(e.target.value)}
          />
          <Form.Text className="text-muted">
            Type to search for an item
          </Form.Text>
        </Form.Group>
      </Form>

      <div className="row">
        {loading ? (
          <Loader></Loader>
        ) : (
          recipes &&
          recipes.map((item, index) => (
            <div className="col-md-4">
              <div className="recipe-img">
                <Link to={`../Recipe-Details?id=${item.foodID}`}>
                  <img
                    className="img-thumbnail"
                    src={
                      window.location.origin +
                      `/img/recipes/${item?.foodID}/${item?.foodID}.jpg`
                    }
                    style={{
                      maxWidth: "100%",
                    }}
                    alt={item.foodTitle}
                  ></img>
                </Link>
              </div>
              <span className="m-3 text-uppercase">
                <h5>{item.foodTitle}</h5>
              </span>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
export default Recipes;
