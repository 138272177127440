import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import parse from "html-react-parser";

interface Resource {
  resourceID: number;
  resourceTitle: string;
  resourceCategory: number;
  resourceDetails: string;
  trainerISID: number;
  resourceStatus: number;
  resourceDate: string;
  resourceURL: string;
  resourceGuid: string;
}

function Resources(): ReactElement {
  const [resource, setResource] = useState<Resource>({
    resourceID: 0,
    resourceTitle: "",
    resourceDetails: "",
    resourceCategory: 0,
    trainerISID: 0,
    resourceStatus: 0,
    resourceDate: "",
    resourceURL: "",
    resourceGuid: "",
  });
  const [deets, setDeets] = useState("");
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<any[]>([]);
  const [resourceID, setResourceID] = useState<any[]>([]);

  const [docs, setDocs] = useState<any[]>([]);

  const apiService = new ApiService();

  const getValue = (e: any) => {
    setLoading(true);

    const id = e;

    const apiService = new ApiService();
    const fetchResourceByID = async () => {
      const response = await apiService.getResourceByID(id);
      setLoading(false);
      setResource(response);

      const deetos = parse(response.resourceDetails);
      setDeets(deetos.toString());
      setResourceID(response.resourceID);
      fetchDocsByMessageGuid(response.resourceGuid);
    };
    fetchResourceByID();
  };

  const fetchDocsByMessageGuid = async (messageGuid: string) => {
    const response = await apiService.getDocsByMessageGuid(messageGuid);
    setDocs(response);
  };

  const fetchResources = async () => {
    setLoading(true);
    const response = await apiService.getAllResources();
    setResources(response);
    getValue(response[0].resourceID);
    setLoading(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Resources</h3>
      <hr></hr>
      <div className="row">
        <div className="col-md-4">
          <ul className="list-group">
            {resources &&
              resources.map((item, index) =>
                item.resourceStatus === 1 ? (
                  <li
                    onClick={(e) => getValue(item.resourceID)}
                    style={{ cursor: "pointer" }}
                    className={
                      resourceID === item.resourceID
                        ? "active list-group-item"
                        : "inactive list-group-item"
                    }
                  >
                    {item.resourceTitle}
                  </li>
                ) : null
              )}
          </ul>
        </div>
        <div className="col-md-8">
          <h3>{resource.resourceTitle}</h3>
          <hr></hr>
          <p dangerouslySetInnerHTML={{ __html: deets }} />
          {docs &&
            docs.map((item, index) => (
              <a
                href={`/PDFStore/resources/${item.messageGuid}/${item.docPath}`}
              >
                Download Resource
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}
export default Resources;
